import React from 'react';
import Typography from '@mui/material/Typography';

// Helper function to compute the award based on final rank.
function getAward(rank) {
  if (rank >= 1 && rank <= 296) return "Semifinalist";
  if (rank >= 297 && rank <= 434) return "Distinguished Scholar";
  if (rank >= 435 && rank <= 817) return "Honor Roll";
  return ""; 
}

const winnersData = [
  "Maheen Imran Shaikh",
  "Rassul Zhunussov",
  "Pranava Senathipathi Kumar",
  "Tatev Dadian",
  "Anjali Sharma-Tiwari",
  "Ameya Shirguppe",
  "Zalea Nunes",
  "Anya Goel",
  "Arjun Karapakula",
  "Cyrus Law",
  "Ramona (Mona) Valiollahi",
  "Vishnu Vijay",
  "Jarupat Bulpakdi",
  "Toha Abedin",
  "Devesh Aggarwal",
  "Omar Emam",
  "Aanya Gupta",
  "Jacob Mingen Gu",
  "Turiya Misra",
  "Seokhwan Jang",
  "Darara Negussie Meskela",
  "Cyrus Ghane",
  "Aimee Yu Zhang",
  "Chen You'An",
  "Md Towhidur Rahman Sakib",
  "Mariam Tutayeva",
  "KYLE ANTHONY J. ROBERTO",
  "Shawn Kim",
  "Amna Abid",
  "Joshua Wung",
  "Lily Chen",
  "Armaan Diwan",
  "Saahil Shah",
  "Sai Likhith Karri",
  "Ameya Kharade",
  "Meruyert Amangeldy",
  "Jayden Joseph",
  "Sammit Chidambaram",
  "Charlotte Sze Wai WONG",
  "Aru Lesbek",
  "Abdullah Goraya",
  "Ojaswi Singh",
  "Subanth Nanda Kumar",
  "Catherine Yang",
  "Keerthana Anumukonda",
  "Pradhyumna Vasishta",
  "Devesh Tiwari",
  "Armin Stamate",
  "Amber Ho Yiu Chow",
  "Aharon Aguirre",
  "Jingyi Ling",
  "Jasmine Sun",
  "Kanushi Desai",
  "Fernando Alonso Cruz Ng Ying",
  "Abhinav Shah",
  "Neal Shandilya",
  "Daksh Mannadath",
  "Dhairya Mittal",
  "Ethan Wei",
  "Rudransh Singh Palta",
  "Iris Tong",
  "Rudra Boghani",
  "Maria Galicia",
  "Tanvi Nare",
  "Claire Cao",
  "Kevin Ouyang",
  "Liu Shuyu, Fish",
  "V. Sang",
  "Sultan Sailau",
  "Anahitaa Jain",
  "Sajeel Muhymin Vehra",
  "Adhvay Karthikeyan",
  "Sophia Hou",
  "Julia Maksimowicz",
  "Hina Yamamoto",
  "Timur Petanin",
  "Alijawad Peera",
  "Taeho Kim",
  "Isabella Podesta",
  "Abhijay Chaudhari",
  "Supriya Krishnamurthy",
  "Jack Agata Pexton",
  "Ishaan Shailesh Surve",
  "Yuvan Aggarwal",
  "Hridank Shukla",
  "Bhrigu Uppal",
  "Hayden Chen",
  "Michael Wang",
  "Panita Chantravekin",
  "William Liang",
  "Sanjith Sowrirajan",
  "Hyunbin Seo",
  "Akash Saran",
  "Adithya V. Sastry",
  "Kelly Lock",
  "Advait Swaminathan",
  "George Thomas",
  "Hridhaan Sanat Banerjee",
  "Uktamjon Komiljonov",
  "Roneet Dhar",
  "Neo Xuan Che",
  "Mehaan Sibal",
  "Kayley Xu",
  "Shankar Harikrishnan",
  "Annie Zhu",
  "Raymond Chen",
  "Venkata Anirudh Devireddy",
  "Martin Izquierdo",
  "Anya Gruteser",
  "Sahasrad Sathish",
  "Guransh",
  "Mardochee Joseph",
  "Colin Ding",
  "Sria Siva",
  "Zahid Syed",
  "Trashgim Mulosmani",
  "Hrishikesh Satish",
  "Vedh Reddy",
  "Rishit Anand",
  "Rachel Dina Rakower",
  "Srishti Hazra",
  "Arjun Desikan",
  "Sangwoo Kim",
  "Ishaan Kumar",
  "Boze Jia",
  "AARYAN PARVEEN",
  "benjamin vaccaro",
  "Ganendra Bisma Mahardika",
  "Hazem Taha",
  "Satya Sriram Kokonda",
  "Abdallah Roshdy Elsyed",
  "Aarav Trivedi",
  "Varun Kumar Hittuvalli",
  "Abhay Gupta",
  "Timothy Hajin Park",
  "Yufei (Joanna) Jiang",
  "Rafael Matteo Dollet",
  "Baruni Soni",
  "Ali Zaruov",
  "Ved Reddy",
  "Saketh SIngamsetty",
  "Omer Mescioglu",
  "Mishri Buddhadev",
  "Shivansh Shukla",
  "Ariana Kam",
  "Mikhail Ibrahim",
  "Sz Kiu Priscilla leung",
  "Leon Jiaxing Yuan",
  "Aye Myatt Naing",
  "Ansh Gupta",
  "Varshith Vijjapu",
  "Srenithi Ganesa Moorthy",
  "Jonathan Liu",
  "Kriti.Atluri",
  "Morgan Selzler",
  "Mikhail Zotov Nikolaevich",
  "Muhammad Zayann Yasir",
  "Amir Mahmoud Ghanem",
  "Michelle Zhou",
  "Charlie Wang",
  "Alex Vladimir",
  "Yuqi Zhang",
  "Shengtian Hong",
  "Roshan Amurthur",
  "Siddharth Yenamandra",
  "Saket Parayil",
  "Chetas Aduri",
  "Neya Rajasingh",
  "Ariane McDermott",
  "Arjun Arobind Nair",
  "Marcus Deng",
  "Dhrithi Karavadi",
  "Jing Yao Yang",
  "Zachary Pan",
  "Lily Bombay",
  "Thora Mattina Gudmundsdottir",
  "Rafael Dollet",
  "Chloe Angela Li",
  "Avyukt Satish",
  "Anthony Wang",
  "Julian Moncarz",
  "Arav Kumar",
  "Shresta Morisetty",
  "Aiden Chun",
  "Rohan Manchanda",
  "Eashan Rautaray",
  "Dhruv Nair",
  "Jasper tay",
  "Ishaan Kabra",
  "Julia Liang",
  "Akshay Thanipet Padmanabhan",
  "Arda Deniz Kanlƒ±",
  "Eliza Zdych",
  "Rheann Laureiz Sioco",
  "Tianle Liang",
  "Ha-mim Rahman",
  "Shreeya Rao Lalam",
  "Leo Tang",
  "Lotus Gao",
  "Denis Boudaliez",
  "Russell Liu",
  "Kwan Tsz Kin",
  "Ozair Ahmad Khan",
  "Adi Yenuubarii",
  "Sayak Moulic",
  "Arth Dalsania",
  "Liang Zhiying",
  "Pradhyun Goud Akkala",
  "Mabel Yang",
  "Isabel Goh",
  "Mabinty Keita",
  "Rastin Emrani",
  "DIVYA JAYAM",
  "Tristan Park",
  "Yongen Shen",
  "Joshua Huang",
  "Simone Maimon",
  "Adhrit Srinivasan",
  "Ayzah Salim Jujara",
  "Ghrini Sai Jella",
  "Lu Renyi",
  "Vrishank Adya Mallela",
  "Eshna Saigol",
  "Vihaan Kinra",
  "Sophie Katherine Huang",
  "Ajsel Budlla",
  "Aw Jun Hong",
  "Le Minh Ngoc",
  "Jeffrey Huang",
  "Aryan Kohli",
  "Bhargav Shah",
  "Sam-Haendell Thosiac",
  "Shiven Das",
  "Ahana Tajnim Khan",
  "Evelyn Yao",
  "Sai Akshith Challagolla",
  "Malak Omer",
  "Ivy Yuetian Ma",
  "Yao",
  "Lasya Gudibanda",
  "Arjun Skanda Ananda",
  "Sami Ihsan Sazci",
  "Samuel Han",
  "Arsh Jha",
  "Stella Myers",
  "Sai Raghav Ganesh",
  "Sreemahati Periyala",
  "Chloe Lam",
  "Adwita Jagannathan",
  "Yanrui Li",
  "Emily Nguyen",
  "Akhil Yalamanchili",
  "Anvith Neelam",
  "Yuancheng Luo",
  "Tanvi Gupta",
  "Attrey Koul",
  "Jade Lam",
  "Vethaanthikaa Karthikeyan",
  "Elie Ciril",
  "Chris Huy Nguyen",
  "Jyotirmay Meena",
  "Bhavya Babbellapati",
  "Elizabeth Vinokurov",
  "Rayhan Roswendi",
  "Chizorom Elleora Okoro",
  "Syeda Zainab Fatima",
  "Rishika Kurma",
  "Jaelyn Jin",
  "Diya Venkataragavan",
  "Heshika Vaitheeswaran",
  "ESHAN DHEERAAJ GEADAM",
  "Prashasti Huliya",
  "Namay Khandelwal",
  "Ashika Sharma",
  "Akhil Kolluru",
  "Matthew Jun",
  "Nyel Ahmed Uffa",
  "Sahasra Yellepeddi",
  "Sufyan Ahmed",
  "Sharvani Mishra",
  "Noor Gowra",
  "Kenisha Verma",
  "Zian Da",
  "Zofia Rutkowska",
  "Aryaman Gupta",
  "Joonseong Lim",
  "Anandhitha Arasu",
  "Aaron Pinto",
  "Sanwarya Ramani",
  "Abhijay R Kodadala",
  "Ashish Mulabagili",
  "Benicio Olson",
  "Aditya Yadav",
  "Jahnavi Sharma",
  "Sophie Huang",
  "Aarushi Sharma",
  "Survani Sinha",
  "Roshini Selvakumar",
  "Abdullah Mohammed",
  "Ram Sumedh Besta",
  "Peerzada Shahmeer Alum",
  "Alastair Go",
  "C. Tibet Pozam",
  "Sofia Kazintseva",
  "Oechsel Theo",
  "Srishanth Ravi",
  "Muhammadyahyo",
  "Jordyn Szczesniak",
  "Klair Tan",
  "Elif Bilge Ka≈ü",
  "Aidan Wong",
  "Anshi Elizabeth Thomas",
  "Samaira Khan",
  "Siyeong Park",
  "Jinghan Zhang",
  "Alyson Henderson",
  "Claire Lily Huang",
  "Vyom Uppar",
  "Miguel Christopher Mok",
  "Deren Toker",
  "Tianyu Li",
  "Harshil Jegan",
  "Utsav Saroha",
  "Rithvik sai reddy kambham",
  "Jiexi Pan",
  "Marie-Jose Rohayem",
  "Yeeun Choi",
  "Vihaan Gupta",
  "Tapasya illuri",
  "Hedy Tang",
  "AldricBenala",
  "Atul Ganesh",
  "Jacquelyn Inocencio",
  "Siri Pulakandam",
  "Anoushka Swaminathan",
  "Srichandra Pallerla",
  "Roaran Katelan",
  "Anusha Sudheer",
  "Chunghyun Kawata",
  "Suhaan Khan",
  "Jobanjot Singh Madhok",
  "Laksan Mohan",
  "Reyo Apollo Schwilk Khan",
  "Morgan Gruber",
  "Eesha Vanamala",
  "Nimeesha Komatireddy",
  "Michelle Diep",
  "Feride Ada Senturk",
  "Iraj Gupta",
  "Yara Aziz",
  "Rohini Vedam",
  "Morgan Duran",
  "Sydney Yu",
  "Sumedha Saha",
  "Paridhi Goel",
  "Avika Taneja",
  "Deeksha shashidhar",
  "Arjan Gavin Singh Waraich",
  "Briana Codrescu",
  "Yena Kim",
  "Lasya Pannala",
  "Aadhavan Veerendra",
  "Andrew Rahana",
  "Aarthi Palaniappan",
  "Rehan Raj",
  "Brooke Foley",
  "Arya Verma",
  "Veer Chopra",
  "William Flaxenburg",
  "Ashley Mahajan",
  "Norah Majed Altamimi",
  "Darshika Domma",
  "Tanya Yong",
  "Aryana Hou",
  "Sathwika Prasad",
  "Aishwarya Kamineni",
  "Collin Lu",
  "Tsz Chai Alan Liu",
  "Haidar Jamal Haidar Haidar",
  "Aaron Sethi",
  "Leo Chen",
  "Hayden Lee",
  "Arjun Vir Jain",
  "Kaushik Tatiraju",
  "Zoya Chowdhury",
  "Xinyi Kathy Xi",
  "Anika Gursahani",
  "Advithi Baddam",
  "Sofia Pisareva",
  "Ansel Belani",
  "Avery Huang",
  "Savya Bhat",
  "Mischa Neo Jing Yi",
  "Dhruva Vengala",
  "Rohith Kolar",
  "Nikhil Palempalle",
  "Mridul Jain",
  "Nicholas McDow",
  "Eslam Essam Ahmed",
  "Chaitra Jogiraju",
  "Eric Jia Zhang",
  "Grace Yuan",
  "Gagana Latchupatula",
  "Sean Haejun Lee",
  "Annalyse McGhee",
  "Narendran Karikkathil",
  "Parth Agarwal",
  "Lina AlKhayyal",
  "Oviya Mathi",
  "Pranav Mohankumar",
  "Petechapat Areewong",
  "Maya Trutschl",
  "Pranav Kunadharaju",
  "Aadi Shah",
  "Neel Jain",
  "Martin Joe Prem Kumar",
  "Jennie Zhang",
  "Jay Patel",
  "Piyush Acharya",
  "Sanjana Rajaram",
  "Wenshu Wang",
  "Stepan Galstyan",
  "Suraj Swaminathan",
  "Tavishi Sinha",
  "Reyansh Mohan Lal",
  "Kausthubha Naraparaju",
  "Jophy Lin",
  "Theodore Mui",
  "Ainsley Bosman",
  "Matthew Young Chi",
  "Ishaan Dabhade",
  "Akchara Mukunthu Deepak Babu",
  "Snehansh Reddy Bachala",
  "Srisaran Sivakumar",
  "Ainesh Yarlagadda",
  "Ying Yue Sakura Wang",
  "Gaon An",
  "Vivaan Awal",
  "Aleksei Medvedev",
  "Misha Tumma",
  "Shivansh Gupta",
  "Wenli Xie",
  "Nathan Tatsuya Tam",
  "Julia Gao",
  "Lizbeth He",
  "Pushkal Kumar",
  "Lamar Ayman Muslem Almuslem",
  "Chau Pham",
  "Syed Mohammad Hamza Warsi",
  "Alison Paez Kestler",
  "Vu Tam Minh",
  "Julian Sethna",
  "Kaytharee Lin Thant",
  "Pranavkrishna Bharanidharan",
  "Julianna Marton",
  "Adhrith Vutukuri",
  "Aryan Pant",
  "Kohei Kawada",
  "Eric Du",
  "Lokesh Pranav Karthik",
  "Sudharshini Ram",
  "Zara Kidwai",
  "Aditya Bhupesh Ghanekar",
  "Aneesh Karavadi",
  "Zainab Mustafa Bharmal",
  "Alicia Gu",
  "AJAY M KOTA",
  "Katrina Javaisas Davis",
  "Arpit Kumar",
  "Owen Shi",
  "Sowmiya Deventhira Raja",
  "Auspy Li",
  "Apichat Chaisarn",
  "Cody Tang",
  "WU Ka Chun",
  "Ruby Jingxuan Huang",
  "Preyahathai Aroonvanichporn",
  "Jahnavi Singh",
  "Aryan Mathur",
  "Manyata Neupane",
  "Auttakorn Wongchai",
  "Adithya Anand",
  "Tay Jie Rong Javyan",
  "Jovan Tran",
  "Zena Kishek",
  "Dominika Wierzchucka",
  "Can Deniz Yazici",
  "Maanit Misra",
  "Yuxin Wang",
  "Jathursha Thavaraajah",
  "Yahaya bin Basiron",
  "Anushka Musaddi",
  "Esabella Sodhi",
  "Luke Patrick Geltner",
  "Ramazan Kanat",
  "Aryan Bellamkonda",
  "Tungat Tomiris Muhamednurkyzy",
  "Ela Sismanyazici",
  "Sarintip Thipjumnun",
  "Gabriele Battimelli",
  "Umar Bobojonov",
  "Khrish Mukesh Butani",
  "Dorian Brian Reed",
  "Guilherme Andrade Decotelli",
  "Eman Khosa",
  "Niran Patel",
  "Sanjana Chandramohan",
  "Roshan Shameem",
  "Hiya shyani",
  "Jia Sheng Clyde Siow",
  "Yoon Ting Jun Xiong",
  "Siena Susan Abraham",
  "Ridhima Abhijit Patil",
  "Prakhar Shrimali",
  "Marika Shimizu",
  "Geet Jaiswal",
  "Delfino Gonzalez",
  "Nguyen Huu An Nguyen",
  "Arnav Guduri",
  "Yuqiao Zhao",
  "Demir Gulen",
  "Divya Shrikanth",
  "Rithwik Nair",
  "Matthew Bock",
  "Taisiia Tymofieieva",
  "Tharun Vijay U.K.",
  "Wafi Sharif",
  "Charanarravindaa Suriess",
  "Aria Mitra",
  "ved khatri",
  "Irene Eun",
  "Mina Shyam",
  "Dhruthi Vinay Bharadhwaj",
  "Justin Tjitra",
  "Tina Ren",
  "Hitesh Konduru",
  "Aarush Upadhya",
  "Tarun Saibabu Chamala",
  "Ranviir Saini",
  "Aditya Sharma",
  "Saanvi Arun",
  "Walid El ouarzazi",
  "Lily Shi",
  "Jeremy Wang",
  "Austin Rylan Xu",
  "Aiswharyaa Lalgudi Nagarajan",
  "Julia Shi",
  "Praneetha Ghattamaneni",
  "Divya Khandelwal",
  "Tom Le",
  "Nikhil Nunna",
  "Jayavardhan Harihar Prasad",
  "Chloe Sanada",
  "Kally Wen",
  "Princeton Lam",
  "Jarin Nudar",
  "Aadhrit Peram",
  "Rathish Raghav RamKumar",
  "Bui Anh Hao",
  "Vedansh Saha",
  "Yash Joshi",
  "Arinjay Banerjee",
  "Mario Orellana",
  "Christopher Ko",
  "Rihan Kachare",
  "Oliver (Zihuan) Xu",
  "Ibraheem Saqib",
  "Hansong Zuo",
  "Xizhe Yang",
  "Suhrit Revuri",
  "Jiwoo Kim",
  "Lily Ma",
  "Vivian Feng",
  "Siraaj Singh",
  "Presha Kashyap",
  "Surya Nikhita Fincke",
  "Asel Alibekova",
  "Diya Rajkumar",
  "Chak Hang Chan",
  "Rishith aggarwal",
  "Cecily Lu",
  "Abhinav Nambi",
  "Ahmad Fikri Zulfikar",
  "Dhravarin Bhuanantanondh",
  "Dan Roby",
  "Adela Wang",
  "Rushil S Yeole",
  "Atharva Sahu",
  "Justin Yuan",
  "Maissie Navarro",
  "Spencer Hirsch",
  "Rejoy Rajesh",
  "Barnabas Kui",
  "Manan Shah",
  "Geetansh Dhuria",
  "Leo Toake",
  "Angela Davis",
  "Shravan Calvin Sridhara",
  "Fatima Zaheer",
  "Bennett Eisley",
  "Sarantina D.",
  "Nidhira Palakolanu",
  "Yew Zi Shuen",
  "Arav Srivastava",
  "Ansh Jain",
  "Adam Kui",
  "Alishba Abubakr Siddiq",
  "Logan Shaper",
  "Platon Gorokhov",
  "Marianna Shekoian",
  "Saisha Sahoo",
  "Riana Das",
  "Arshaan Khurana",
  "Jayden chen",
  "Jiarui Jackie Li",
  "Shreya Munjal",
  "Selena Ghane",
  "Marsha Nath",
  "Kennard Liong",
  "Noran Hassan",
  "Dua Nadeem",
  "Saathvik Voora",
  "Isaac Xu",
  "Yoonha Lee",
  "Roberto Moura de Souza Leao",
  "Reethi Reddy Kumbham",
  "Julia Jose",
  "Siya Karani",
  "Georgia Marie Newberry",
  "Avani Malhotra",
  "Kairatov Assiretaly",
  "Manasvi Dhakite",
  "Angelina Lanskikh",
  "Maksym Turchenko",
  "Nannalin Pornprasertsom",
  "Pranav Kannan",
  "Sophia Alejandra Serra Bautista",
  "Tanisha Thaosen",
  "Dinmukhammed Kemelov",
  "Yi Han",
  "Hector Hinojosa",
  "Chua Leyang",
  "Vanya Karan Gupta",
  "Lavrentyev Artem",
  "Kaavya Bhatt",
  "Anya Chitturi",
  "Shiven Sharma",
  "Amrit Kar",
  "Qiu Caroline",
  "Shrey Jain",
  "Safiyakhon Zulfikarova",
  "Ajay Koumar Sassikoumar",
  "Noor Koshok",
  "Chloe Elkareh",
  "Shanaya Ailsinghani",
  "Gagan Chitragar",
  "Elaina Chen",
  "LIN, KAI-LUN",
  "Ashwin Ramachandran",
  "Mushekbayev Amir",
  "Himesh Mallula",
  "Fung Kai Jing Hay",
  "Puranjay Haldankar",
  "Vansh Shah",
  "Advika Sahney",
  "Amy Nangia",
  "Rishika Subudhi",
  "Aarya Kulkarni",
  "Ada Brunner",
  "Rachit Agarwala",
  "Yuriko Tanaka",
  "Allegra Baschiera",
  "Amy Nangia",
  "Sofia Teodora Dragomir",
  "Mikhail Aleksandrov",
  "Krishanu Karun",
  "Srirama Desiraju",
  "Linnea Nora Riehl",
  "Isaac Engel",
  "Kashika R",
  "Cindy Gu",
  "Jahnavi Balaji",
  "Mariana Barbosa Fialho",
  "Nathan Huang",
  "Vidyuth Pasumarthi",
  "Suchir Dubey",
  "Iraj Shroff",
  "Isabel Zhou",
  "Aadit Shrivastava",
  "Suin Jung",
  "Lorcan Purcell",
  "Adrian Huang",
  "Hui Ting Wai",
  "Emma Wang",
  "Nhat Minh Vu",
  "Maanit Khanna",
  "Kaavya Goel",
  "Tanushri Ciganehalli",
  "Sashwath Ravishankar",
  "Aiden Pereira",
  "Medha Dontineni",
  "Sai Abhiram Pathaneni",
  "Iva Talwar",
  "Jef Zember Gregorio",
  "Andrew Zhou",
  "Myesha Mahashin",
  "Om Mahesh Patel",
  "Shrividya Regadamilli",
  "Adelyne Kristie Pandji",
  "Jonathan Song",
  "Aanya Rampuria",
  "Nitya Dani",
  "Javier Leng Jae Shaen",
  "Navneeth Badhri",
  "Ariana Dharmendra",
  "Asher Larsen",
  "Abdullah Abdulrahman Alharbi",
  "Arnav Maharjan",
  "Shreyas Taduri",
  "Rishika",
  "Elaine Wynette Wijaya",
  "Bolat Dariya",
  "Sandhya Lakshmanan",
  "Vihaan Sawardekar",
  "Manit Murthy",
  "Aryan Chavda",
  "Dev Saxena",
  "Viren Chauhan",
  "Brent Dara Yi",
  "Sheryl Selvaraj",
  "Siddharth Manne",
  "Tanish Kadarapura",
  "Seraphim Soliman",
  "Sunwoo Whang",
  "Yared Atrsaw Andargei",
  "Jonathan Peter Zapris",
  "Rishabh Kharait",
  "Ridhvi Shetty",
  "Advait Singh",
  "Maggie Zheng",
  "Vsronica Xu",
  "Sameeha Sana",
  "Harper Carnahan",
  "Chinmay Patil",
  "Maryia Kisialiova",
  "Harihar Vadrevu",
  "Zion Zhang",
  "Danu Kim",
  "Nabiha Islam",
  "Prayagh Prabhakar",
  "Sukrit Kaur",
  "Sophia Annunziata",
  "Nguyen Khunh An",
  "Rocco John Rush",
  "Eunsul Kim",
  "Aarush Anand",
  "Aaqil Shaik",
  "Shubhi Jain",
  "Ethan Li Ngan Sun",
  "Carson Overholtzer",
  "Yerin Cho",
  "Kevin Guo",
  "Divya Thirumala",
  "Mukul",
  "Mohammad Ismail Sher",
  "Aanya Tripathi",
  "Sanjana Avvaru",
  "Pranav Kunisetty",
  "Serena Ni",
  "Ahilesh Sivakumar",
  "Batal Amirbek",
  "Jasmine Wong",
  "Arjun Srinivasan",
  "Aarush Gunukula",
  "Aashna Dhriti",
  "Jeremy Yang",
  "Nicole Vinod",
  "Sydney Choi",
  "Quang Anh Nguyen",
  "Samuel Fletcher Harris",
  "Peter Jiang",
  "Ray Tian",
  "Aneesh Bondugula",
  "Abhirama Sonny",
  "Abigail Qi",
  "Ethan Mitchell Kim",
  "Kaylee Song",
  "Anju Advani",
  "Ben Bui",
  "Ellen Wei",
  "Name",
  "Eshan Vijaykumar Patil",
  "Kinjal",
  "Roy Seligman",
  "Guneesha Kaur",
  "Saathvik Valvekar",
  "Pranav Yogeswaran",
  "Kaleb Lee",
  "Avyang Tiwari",
  "Rajiv Srivastava",
  "Eshaan Nidee",
  "Josh Karthikeyan",
  "Raunak Singh",
  "Anna Zavadski",
  "Vinayak Jinez Manikandan",
  "MIHANA NAKAJIMA",
  "Brianna Su",
  "Jacob Gaochen",
  "Ved Parag Bhagat",
  "Jorge Wu Wu",
  "Eric Chengyu Sun",
  "Matilda Thomas",
  "Stephen Tao",
  "Ashwin Raghav",
  "Haiqa Sajid",
  "Jenelle Sowah-Klu",
  "Rohan Modi"
];

function Winners() {
  const winners = winnersData.map((name, index) => {
    const rank = index + 1;
    const award = getAward(rank);
    return (
      <tr key={rank}>
        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>{rank}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{name}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>{award}</td>
      </tr>
    );
  });

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "900px", margin: "auto" }}>
      <Typography variant="overline" display="block" gutterBottom style={{ fontSize: "1.5ch" }}>
        3/20/25
      </Typography>
      <Typography variant="h4" style={{ color: "#1E88E5", marginBottom: "20px" }}>
        Honor Roll - Top 40%
      </Typography>
      <table style={{ borderCollapse: "collapse", width: "100%", marginBottom: "30px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Final Rank</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Award</th>
          </tr>
        </thead>
        <tbody>
          {winners}
        </tbody>
      </table>
      <div style={{ marginTop: "20px" }}>
        <Typography variant="body1" style={{ marginBottom: "10px" }}>
          If a certificate was not received, please email <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a>.
        </Typography>
        <Typography variant="body1">
          If a semifinalist confirmation email was not sent, please email <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a> immediately with the full name and school from the same email used for registration.
        </Typography>
      </div>
    </div>
  );
}

export default Winners;
