import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ORAndHRR from './Opens-results-and-honor-roll-released';

const Announcement = () => {
  const navigate = useNavigate();

  const loginpage = () => {
    navigate("/register")
  
  }
  return (
    <>
       <div className=" containere" data-aos="zoom-in-up">
        <div className="infoe">
          <a href="/opens-results-and-honor-roll-released" style={{textDecoration: 'none', color: 'inherit'}}>
          <p style={{marginTop: '8px', fontSize: '16px'}}>
            {/* <strong className="sdflknsrj" style={{fontSize: '16px', fontFamily: "serif"}}>Register before it's too late!</strong> */}
            <Typography variant="overline" >
            <b >ANNouncement:</b> Opens Results & Honor Roll Released
            </Typography>
            {/* <a href="/register" style={{color: "black"}} placement="right-start">
              <ArrowForwardIcon style={{marginBottom: "2px"}}/>
            </a> */}
          </p>
          </a>
        </div>
        </div>
    </>
  );
};

export default Announcement;
