// src/pages/Textbook.js
import React, { useEffect } from "react";

const Textbook = () => {
  useEffect(() => {
    // Redirect to the external textbook website on component mount
    window.location.href = "https://iro-textbook.vercel.app/";
  }, []);

  return (
    <div>
      <h2>Redirecting to the Textbook...</h2>
      <p>If you're not redirected automatically, click <a href="https://iro-textbook.vercel.app/">here</a>.</p>
    </div>
  );
};

export default Textbook;
