import React, { useState, useEffect } from 'react';
import { storage } from '../firebase';
import MuiAlert from '@mui/material/Alert';

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import db from "../firebase";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Feewaiver() {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        school: '',
        grade: '',
        country: '',
        phone: '',
    });
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);

    const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      
    } else {
     
      setFile(null);
    }
  };

  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        const { name, school, grade, country } = formData;
    
        // Check if all required fields are filled
        if (name && school && grade && country) {
            if (file) {
                if (file.size <= 5 * 1024 * 1024) {
                    const storageRef = ref(storage, `pdfs/${file.name}`);
                    await uploadBytes(storageRef, file);
                } else {
                    setErrorOpen(true); // File size exceeds limit
                    return;
                }
            }
            // Check if a file is selected and its size is 5MB or less
                try {

                    // const url = await getDownloadURL(storageRef);
                    await db.collection("IRO-fee-waiver").add({
                        ...formData,
                        date: new Date(), 
                    });
                    setSuccessOpen(true);
                    setFormData({name: '', school: '', grade: '', country: '', phone: ''});
                    setActiveStep(1);
                } catch (error) {
                    setErrorOpen(true);
                }
            
        } else {
            // Handle the case where required fields are missing
            setErrorOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSuccessOpen(false);
        setErrorOpen(false);
    };


    return (
        <div  style={{paddingTop: "50px"}} className="container">
            <div className="min-h-screen p-12 m-12 flex flex-col items-center justify-center">
            <Box
                            style={{padding: "50px"}}
                                className="p-12 bg-white shadow-lg rounded-lg"
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <h1 className="text-xl font-bold text-center mb-6">IRO 2025 Registration</h1>

                               

                                <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                                    <p className="secondarytitle">Fee Waiver Form</p>
                                </div>

                                <p className="lowertitle" data-aos="fade-up">
                                To be eligible to compete, you must meet the below criteria:


                                    </p>

                                    <ul class="list-group list-group-flush">
                                    <li class="list-group-item"></li>
                                    <li class="list-group-item">Be enrolled in grades 6-12 or an equivalent homeschool program. <br/> This includes both traditional public and private high schools, as well as accredited online programs.</li>
                                    <br/><br/>
                                    </ul>

                                    <p className="lowertitle" data-aos="fade-up">
                                        If you meet the above criteria, great! You qualify to participate in the International Research Olympiad. Be sure to register for the competition below.
                                    </p>
                                    <p className="lowertitle" data-aos="fade-up">
                                       To submit the fee waiver form, upload PDF proof of reduced lunch, CommonApp fee waiver, or ACT/SAT waiver. You may also send income tax returns or bank statements compiled into a single PDF
                                       Your fee waiver application will be processed by the IRO's financial aid department in the coming weeks. You will be emailed by February 28 with a response.

                                    </p>

                                    <div style={{paddingTop: "20px"}}></div>

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    value={formData.name}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="School"
                                    name="school"
                                    variant="outlined"
                                    value={formData.school}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Grade"
                                    name="grade"
                                    variant="outlined"
                                    value={formData.grade}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Country"
                                    name="country"
                                    variant="outlined"
                                    value={formData.country}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="phone"
                                    variant="outlined"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />

                                <p style={{fontSize: "15px", marginTop: "20px"}}>(Required) Upload PDF proof of reduced lunch, CommonApp fee waiver, or ACT/SAT waiver. <br/> Please name the file as <b>FIRSTNAME_LASTNAME.pdf</b> and ensure it's under <b>5MB</b>.</p>

                                <input
                                    style={{marginTop: "10px", marginBottom: "20px"}}
                                    type="file"
                                    className="hidden"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                                />


                                <Button

                                    fullWidth
                                    variant="outlined"
                                    className="mt-12"
                                    onClick={handleSubmit}
                                    sx={{
                                        marginTop: '20px',
                                        borderColor: 'black',
                                        color: 'black',
                                        '&:hover': {
                                            borderColor: 'black',
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                >
                                    Submit
                                </Button>

                                <div style={{color: "gray", marginTop: "15px"}}>
                                <Typography  variant="caption" className="block mt-12 text-gray-100">
                                This registration does not constitute any financial commitment or contractual obligation. {' '}
                                 </Typography>
                                 {/* <br/>
                                <Typography  variant="caption" className="block mt-12 text-gray-100">
                                        If you qualify for a fee waiver, please submit a 5MB or less FIRSTNAME_LASTNAME.pdf of proof. {' '}
                                 </Typography>
                                 <input
                                    style={{fontSize: "10px",
                                    opacity: "60%",
                                    border: "none"
                                
                                }}
                                    type="file"
                                    className="hidden"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                                />
                                <br/> */}
                               
                                </div>
                            </Box>
                            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Registration successful!
                    </Alert>
                </Snackbar>

                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        Please fill required fields.
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default Feewaiver;
