import React, { useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import washtimes from "../newslogos/the-times-logo-png-2.png"
import wtopnnews from "../newslogos/wtop-news-logo-new.png"
import johnnnews from "../newslogos/JCPCircle.png"
import pplnews from "../newslogos/Focus-Logo.gif"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import AOS from "aos";
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import '../App.css'

const newsdata = [
  { name: 'The Harvard Crimson', href: 'https://www.thecrimson.com/sponsored/article/top-50-stem-programs-for-high-school-students/', image: 'https://s3.amazonaws.com/thumbnails.thecrimson.com/photos/2022/09/02/054426_1357404.png.1971x2000_q95_crop-smart_upscale.png', description: 'Top 50 STEM Programs for High School Students', footer: '' }, 
  { name: 'Washington Times', href: 'https://www.washingtontimes.com/news/2025/feb/1/emma-tukhi-16-of-virginia-to-publish-research-pape/', image: "https://tiffany.house.gov/sites/evo-subsites/tiffany.house.gov/files/evo-media-image/untitled-design_1.png", description: 'Virginia teen to publish medical research paper, helps promote contest to urge peers to do likewise', footer: '' },
  { name: 'Forbes', href: 'https://forbes.com.br/forbes-mulher/2025/02/menina-mulher-na-ciencia-millena-xavier-brasileira-17-anos-prep-olimpiadas/', image: "https://static.vecteezy.com/system/resources/previews/027/127/545/original/forbes-logo-forbes-icon-transparent-free-png.png", description: '17-year-old Brazilian promotes Student Olympics', footer: '' },
  { name: 'US Insider', href: 'https://usinsider.com/new-online-stem-competition-international-research-olympiad/', image: "https://images.squarespace-cdn.com/content/v1/5bbbd9bf7a1fbd1cd63702f4/1698858127401-ANETK6VVUIF24QGYYVFE/US+Insider.png", description: 'New Online STEM Competition: International Research Olympiad', footer: '' },
  { name: 'India Times', href: 'https://www.indiatimes.com/news/education/interested-in-research-international-research-olympiad-inspires-student-research-excellence-627891.html', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI7Uwx7SmzkTapkxC7ipgV-2djxR5YRwqXpQ&s", description: 'Interested In Research? International Research Olympiad Inspires Student Research Excellence', footer: '' },
  { name: 'Town Times', href: 'https://www.ttownmedia.com/tracy_press/mhhs-senior-makes-top-1-at-international-research-olympiad/article_4ff07e88-5a81-11ef-a874-c31e6b02f715.html', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThohJHi6ZfZb_7lw6zOxzoTCQmt2aWpN_byQ&s", description: 'MHHS senior makes top 1% at International Research Olympiad', footer: '' },
  { name: 'Johnson County Post', href: 'https://johnsoncountypost.com/2025/02/01/leena-dudi-international-research-olympiad-224733//', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7rLr_amyTzBaBKuvDnL7ob2tBR3qBdPKLSg&s", description: 'This Johnson County teen is helping launch new international science contest', footer: '' },
  { name: 'Wtop News', href: 'https://wtop.com/fairfax-county/2025/01/fairfax-county-teen-among-first-to-join-international-research-olympiad/', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrlZEZSQKtCFzHCCY4XRkK5BB7t9QwtzlAew&s", description: 'Fairfax County teen among first to join International Research Olympiad', footer: '' },
  { name: 'Forest Grove News Times', href: 'https://www.forestgrovenewstimes.com/lifestyle/features/westview-high-grad-sets-up-competitive-international-research-contest/article_273f5d17-0beb-5a80-8bcf-0a35b0bc32e0.html', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpRbtJ1zv5KjSGRs5AwvxRw_7i20EkR-OYqg&s", description: 'Westview High grad sets up competitive international research contest', footer: '' },
  { name: 'Parsippany Focus', href: 'https://parsippanyfocus.com/2025/01/31/parsippany-teen-joins-international-research-olympiad-leadership-team-to-inspire-future-scientists/', image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrVvENWgrDFBk_XwdLvEUkh36O_l-x4IN1pw&s", description: 'Parsippany Teen Joins International Research Olympiad Leadership Team to Inspire Future Scientists', footer: '' },
]

function News() {

  useEffect(() => {
    AOS.init({ duration: 2000 }); //might need to be AOS
  }, []);

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  

  const gotojohnstownews = () => {
    window.location.href = "https://johnsoncountypost.com/2025/02/01/leena-dudi-international-research-olympiad-224733//"
    
  }


  const gotowashpost = () => {
    window.location.href = "https://www.washingtontimes.com/news/2025/feb/1/emma-tukhi-16-of-virginia-to-publish-research-pape/"
    
  }

  const gotowtopnews = () => {
    window.location.href = "https://wtop.com/fairfax-county/2025/01/fairfax-county-teen-among-first-to-join-international-research-olympiad/"
    
  }

  const gotopartinews = () => {
    window.location.href = "https://parsippanyfocus.com/2025/01/31/parsippany-teen-joins-international-research-olympiad-leadership-team-to-inspire-future-scientists/"
    
  }



  return (
   
      <div className='container'>
        <div className='row rowpad1'>
          <div className='col' style={{paddingTop: "30px"}} data-aos="fade-up">
            <p className="maintitle bigblueonthewing">IRO in the News</p>
            <hr style={{marginTop:'30px'}}/>
          </div>
        </div>


        <div className='row rowpad1' data-aos="fade-up">

        {newsdata.map((item) => (
          <div>
              <div className='row' style={{paddingTop: "15px", paddingLeft:'5px'}}>
               <p className="secondarytitle">{item.name}</p>
              </div>
            
            <Card sx={{ width: "auto", display: 'flex' }} >
              
              <CardMedia
                  component="img"
                  sx={{ width: 105, height: 105, padding: "5px", marginBottom: "auto", marginTop: "auto" }}
                  image={item.image}
                  alt="Live from space album cover"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <p>
                      {item.description}
                    </p>
                  
                    <a href={item.href} style={{color: "black"}} variant="outlined">Read  <ArrowOutwardIcon/></a>
                    {/* <Typography style={{marginTop: "10px"}} variant="overline" display="block" gutterBottom>
                      By Sean Salai - The Washington Times - Thursday, February 1, 2025
                    </Typography> */}
                  
                    
                  </CardContent>
                
                </Box>

            
                
            </Card>
          </div>

        ))}


 
         </div>


      </div>
    
  )
}

export default News
