import React from 'react';
import Typography from '@mui/material/Typography';

function Winners() {
  return (
    <div className="container">
      <div className="col" style={{ paddingTop: "50px" }}>
        <Typography style={{ fontSize: "1.5ch" }} variant="overline" display="block" gutterBottom>
          3/20/25
        </Typography>
        <div className="col" style={{ paddingTop: "50px" }}>
          <p className="maintitle bigblueonthewing">Semifinalists - Top 15%</p>
        </div>
        <p className="lowertitle">
          International Research Olympiad Olympiad Semi-Finalists Announced.
        </p>
        <p className="lowertitle">
          Congratulations to the IRO semi-finalists. We believe your potential in research is unlimited and deep. We stand committed to making this community into a close, innovative, welcoming, and supportive society around student research aspirations,” says Rishab Jain, Student Board Member & President, IRO.
        </p>
        <p className="lowertitle">
          The International Research Olympiad is a platform that strives to create and promote research culture among high school students. This is done by providing a variety of services, tools, and support that aim to streamline the research process. The IRO seeks to give undergraduate students the opportunity to develop their research proficiency, learn new methodologies, and collaborate at an international scale.
        </p>
      </div>
      <div className="row rowpad1" data-aos="fade-up">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Maheen Imran Shaikh</li>
          <li className="list-group-item">Rassul Zhunussov</li>
          <li className="list-group-item">Pranava Senathipathi Kumar</li>
          <li className="list-group-item">Tatev Dadian</li>
          <li className="list-group-item">Anjali Sharma-Tiwari</li>
          <li className="list-group-item">Ameya Shirguppe</li>
          <li className="list-group-item">Zalea Nunes</li>
          <li className="list-group-item">Anya Goel</li>
          <li className="list-group-item">Arjun Karapakula</li>
          <li className="list-group-item">Cyrus Law</li>
          <li className="list-group-item">Ramona (Mona) Valiollahi</li>
          <li className="list-group-item">Vishnu Vijay</li>
          <li className="list-group-item">Jarupat Bulpakdi</li>
          <li className="list-group-item">Toha Abedin</li>
          <li className="list-group-item">Devesh Aggarwal</li>
          <li className="list-group-item">Omar Emam</li>
          <li className="list-group-item">Aanya Gupta</li>
          <li className="list-group-item">Jacob Mingen Gu</li>
          <li className="list-group-item">Turiya Misra</li>
          <li className="list-group-item">Seokhwan Jang</li>
          <li className="list-group-item">Darara Negussie Meskela</li>
          <li className="list-group-item">Cyrus Ghane</li>
          <li className="list-group-item">Aimee Yu Zhang</li>
          <li className="list-group-item">Chen You'An</li>
          <li className="list-group-item">Md Towhidur Rahman Sakib</li>
          <li className="list-group-item">Mariam Tutayeva</li>
          <li className="list-group-item">Kyle Anthony J. Roberto</li>
          <li className="list-group-item">Shawn Kim</li>
          <li className="list-group-item">Amna Abid</li>
          <li className="list-group-item">Joshua Wung</li>
          <li className="list-group-item">Lily Chen</li>
          <li className="list-group-item">Armaan Diwan</li>
          <li className="list-group-item">Saahil Shah</li>
          <li className="list-group-item">Sai Likhith Karri</li>
          <li className="list-group-item">Ameya Kharade</li>
          <li className="list-group-item">Meruyert Amangeldy</li>
          <li className="list-group-item">Jayden Joseph</li>
          <li className="list-group-item">Sammit Chidambaram</li>
          <li className="list-group-item">Charlotte Sze Wai WONG</li>
          <li className="list-group-item">Aru Lesbek</li>
          <li className="list-group-item">Abdullah Goraya</li>
          <li className="list-group-item">Ojaswi Singh</li>
          <li className="list-group-item">Subanth Nanda Kumar</li>
          <li className="list-group-item">Catherine Yang</li>
          <li className="list-group-item">Keerthana Anumukonda</li>
          <li className="list-group-item">Pradhyumna Vasishta</li>
          <li className="list-group-item">Devesh Tiwari</li>
          <li className="list-group-item">Armin Stamate</li>
          <li className="list-group-item">Amber Ho Yiu Chow</li>
          <li className="list-group-item">Aharon Aguirre</li>
          <li className="list-group-item">Jingyi Ling</li>
          <li className="list-group-item">Jasmine Sun</li>
          <li className="list-group-item">Kanushi Desai</li>
          <li className="list-group-item">Fernando Alonso Cruz Ng Ying</li>
          <li className="list-group-item">Abhinav Shah</li>
          <li className="list-group-item">Neal Shandilya</li>
          <li className="list-group-item">Daksh Mannadath</li>
          <li className="list-group-item">Dhairya Mittal</li>
          <li className="list-group-item">Ethan Wei</li>
          <li className="list-group-item">Rudransh Singh Palta</li>
          <li className="list-group-item">Iris Tong</li>
          <li className="list-group-item">Rudra Boghani</li>
          <li className="list-group-item">Maria Galicia</li>
          <li className="list-group-item">Tanvi Nare</li>
          <li className="list-group-item">Claire Cao</li>
          <li className="list-group-item">Kevin Ouyang</li>
          <li className="list-group-item">Liu Shuyu, Fish</li>
          <li className="list-group-item">V. Sang</li>
          <li className="list-group-item">Sultan Sailau</li>
          <li className="list-group-item">Anahitaa Jain</li>
          <li className="list-group-item">Sajeel Muhymin Vehra</li>
          <li className="list-group-item">Adhvay Karthikeyan</li>
          <li className="list-group-item">Sophia Hou</li>
          <li className="list-group-item">Julia Maksimowicz</li>
          <li className="list-group-item">Hina Yamamoto</li>
          <li className="list-group-item">Timur Petanin</li>
          <li className="list-group-item">Alijawad Peera</li>
          <li className="list-group-item">Taeho Kim</li>
          <li className="list-group-item">Isabella Podesta</li>
          <li className="list-group-item">Abhijay Chaudhari</li>
          <li className="list-group-item">Supriya Krishnamurthy</li>
          <li className="list-group-item">Jack Agata Pexton</li>
          <li className="list-group-item">Ishaan Shailesh Surve</li>
          <li className="list-group-item">Yuvan Aggarwal</li>
          <li className="list-group-item">Hridank Shukla</li>
          <li className="list-group-item">Bhrigu Uppal</li>
          <li className="list-group-item">Hayden Chen</li>
          <li className="list-group-item">Michael Wang</li>
          <li className="list-group-item">Panita Chantravekin</li>
          <li className="list-group-item">William Liang</li>
          <li className="list-group-item">Sanjith Sowrirajan</li>
          <li className="list-group-item">Hyunbin Seo</li>
          <li className="list-group-item">Akash Saran</li>
          <li className="list-group-item">Adithya V. Sastry</li>
          <li className="list-group-item">Kelly Lock</li>
          <li className="list-group-item">Advait Swaminathan</li>
          <li className="list-group-item">George Thomas</li>
          <li className="list-group-item">Hridhaan Sanat Banerjee</li>
          <li className="list-group-item">Uktamjon Komiljonov</li>
          <li className="list-group-item">Roneet Dhar</li>
          <li className="list-group-item">Neo Xuan Che</li>
          <li className="list-group-item">Mehaan Sibal</li>
          <li className="list-group-item">Kayley Xu</li>
          <li className="list-group-item">Shankar Harikrishnan</li>
          <li className="list-group-item">Annie Zhu</li>
          <li className="list-group-item">Raymond Chen</li>
          <li className="list-group-item">Venkata Anirudh Devireddy</li>
          <li className="list-group-item">Martin Izquierdo</li>
          <li className="list-group-item">Anya Gruteser</li>
          <li className="list-group-item">Sahasrad Sathish</li>
          <li className="list-group-item">Guransh</li>
          <li className="list-group-item">Mardochee Joseph</li>
          <li className="list-group-item">Colin Ding</li>
          <li className="list-group-item">Sria Siva</li>
          <li className="list-group-item">Zahid Syed</li>
          <li className="list-group-item">Trashgim Mulosmani</li>
          <li className="list-group-item">Hrishikesh Satish</li>
          <li className="list-group-item">Vedh Reddy</li>
          <li className="list-group-item">Rishit Anand</li>
          <li className="list-group-item">Rachel Dina Rakower</li>
          <li className="list-group-item">Srishti Hazra</li>
          <li className="list-group-item">Arjun Desikan</li>
          <li className="list-group-item">Sangwoo Kim</li>
          <li className="list-group-item">Ishaan Kumar</li>
          <li className="list-group-item">Boze Jia</li>
          <li className="list-group-item">Aaryan Parveen</li>
          <li className="list-group-item">benjamin vaccaro</li>
          <li className="list-group-item">Ganendra Bisma Mahardika</li>
          <li className="list-group-item">Hazem Taha</li>
          <li className="list-group-item">Satya Sriram Kokonda</li>
          <li className="list-group-item">Abdallah Roshdy Elsyed</li>
          <li className="list-group-item">Aarav Trivedi</li>
          <li className="list-group-item">Varun Kumar Hittuvalli</li>
          <li className="list-group-item">Abhay Gupta</li>
          <li className="list-group-item">Timothy Hajin Park</li>
          <li className="list-group-item">Yufei (Joanna) Jiang</li>
          <li className="list-group-item">Rafael Matteo Dollet</li>
          <li className="list-group-item">Baruni Soni</li>
          <li className="list-group-item">Ali Zaruov</li>
          <li className="list-group-item">Ved Reddy</li>
          <li className="list-group-item">Saketh SIngamsetty</li>
          <li className="list-group-item">Omer Mescioglu</li>
          <li className="list-group-item">Mishri Buddhadev</li>
          <li className="list-group-item">Shivansh Shukla</li>
          <li className="list-group-item">Ariana Kam</li>
          <li className="list-group-item">Mikhail Ibrahim</li>
          <li className="list-group-item">Sz Kiu Priscilla leung</li>
          <li className="list-group-item">Leon Jiaxing Yuan</li>
          <li className="list-group-item">Aye Myatt Naing</li>
          <li className="list-group-item">Ansh Gupta</li>
          <li className="list-group-item">Varshith Vijjapu</li>
          <li className="list-group-item">Srenithi Ganesa Moorthy</li>
          <li className="list-group-item">Jonathan Liu</li>
          <li className="list-group-item">Kriti.Atluri</li>
          <li className="list-group-item">Morgan Selzler</li>
          <li className="list-group-item">Mikhail Zotov Nikolaevich</li>
          <li className="list-group-item">Muhammad Zayann Yasir</li>
          <li className="list-group-item">Amir Mahmoud Ghanem</li>
          <li className="list-group-item">Michelle Zhou</li>
          <li className="list-group-item">Charlie Wang</li>
          <li className="list-group-item">Alex Vladimir</li>
          <li className="list-group-item">Yuqi Zhang</li>
          <li className="list-group-item">Shengtian Hong</li>
          <li className="list-group-item">Roshan Amurthur</li>
          <li className="list-group-item">Siddharth Yenamandra</li>
          <li className="list-group-item">Saket Parayil</li>
          <li className="list-group-item">Chetas Aduri</li>
          <li className="list-group-item">Neya Rajasingh</li>
          <li className="list-group-item">Ariane McDermott</li>
          <li className="list-group-item">Arjun Arobind Nair</li>
          <li className="list-group-item">Marcus Deng</li>
          <li className="list-group-item">Dhrithi Karavadi</li>
          <li className="list-group-item">Jing Yao Yang</li>
          <li className="list-group-item">Zachary Pan</li>
          <li className="list-group-item">Lily Bombay</li>
          <li className="list-group-item">Thora Mattina Gudmundsdottir</li>
          <li className="list-group-item">Rafael Dollet</li>
          <li className="list-group-item">Chloe Angela Li</li>
          <li className="list-group-item">Avyukt Satish</li>
          <li className="list-group-item">Anthony Wang</li>
          <li className="list-group-item">Julian Moncarz</li>
          <li className="list-group-item">Arav Kumar</li>
          <li className="list-group-item">Shresta Morisetty</li>
          <li className="list-group-item">Aiden Chun</li>
          <li className="list-group-item">Rohan Manchanda</li>
          <li className="list-group-item">Eashan Rautaray</li>
          <li className="list-group-item">Dhruv Nair</li>
          <li className="list-group-item">Jasper tay</li>
          <li className="list-group-item">Ishaan Kabra</li>
          <li className="list-group-item">Julia Liang</li>
          <li className="list-group-item">Akshay Thanipet Padmanabhan</li>
          <li className="list-group-item">Arda Deniz Kanlƒ±</li>
          <li className="list-group-item">Eliza Zdych</li>
          <li className="list-group-item">Rheann Laureiz Sioco</li>
          <li className="list-group-item">Tianle Liang</li>
          <li className="list-group-item">Ha-mim Rahman</li>
          <li className="list-group-item">Shreeya Rao Lalam</li>
          <li className="list-group-item">Leo Tang</li>
          <li className="list-group-item">Lotus Gao</li>
          <li className="list-group-item">Denis Boudaliez</li>
          <li className="list-group-item">Russell Liu</li>
          <li className="list-group-item">Kwan Tsz Kin</li>
          <li className="list-group-item">Ozair Ahmad Khan</li>
          <li className="list-group-item">Adi Yenuubarii</li>
          <li className="list-group-item">Sayak Moulic</li>
          <li className="list-group-item">Arth Dalsania</li>
          <li className="list-group-item">Liang Zhiying</li>
          <li className="list-group-item">Pradhyun Goud Akkala</li>
          <li className="list-group-item">Mabel Yang</li>
          <li className="list-group-item">Isabel Goh</li>
          <li className="list-group-item">Mabinty Keita</li>
          <li className="list-group-item">Rastin Emrani</li>
          <li className="list-group-item">Divya Jayam</li>
          <li className="list-group-item">Tristan Park</li>
          <li className="list-group-item">Yongen Shen</li>
          <li className="list-group-item">Joshua Huang</li>
          <li className="list-group-item">Simone Maimon</li>
          <li className="list-group-item">Adhrit Srinivasan</li>
          <li className="list-group-item">Ayzah Salim Jujara</li>
          <li className="list-group-item">Ghrini Sai Jella</li>
          <li className="list-group-item">Lu Renyi</li>
          <li className="list-group-item">Vrishank Adya Mallela</li>
          <li className="list-group-item">Eshna Saigol</li>
          <li className="list-group-item">Vihaan Kinra</li>
          <li className="list-group-item">Sophie Katherine Huang</li>
          <li className="list-group-item">Ajsel Budlla</li>
          <li className="list-group-item">Aw Jun Hong</li>
          <li className="list-group-item">Le Minh Ngoc</li>
          <li className="list-group-item">Jeffrey Huang</li>
          <li className="list-group-item">Aryan Kohli</li>
          <li className="list-group-item">Bhargav Shah</li>
          <li className="list-group-item">Sam-Haendell Thosiac</li>
          <li className="list-group-item">Shiven Das</li>
          <li className="list-group-item">Ahana Tajnim Khan</li>
          <li className="list-group-item">Evelyn Yao</li>
          <li className="list-group-item">Sai Akshith Challagolla</li>
          <li className="list-group-item">Malak Omer</li>
          <li className="list-group-item">Ivy Yuetian Ma</li>
          <li className="list-group-item">Yao</li>
          <li className="list-group-item">Lasya Gudibanda</li>
          <li className="list-group-item">Arjun Skanda Ananda</li>
          <li className="list-group-item">Sami Ihsan Sazci</li>
          <li className="list-group-item">Samuel Han</li>
          <li className="list-group-item">Arsh Jha</li>
          <li className="list-group-item">Stella Myers</li>
          <li className="list-group-item">Sai Raghav Ganesh</li>
          <li className="list-group-item">Sreemahati Periyala</li>
          <li className="list-group-item">Chloe Lam</li>
          <li className="list-group-item">Adwita Jagannathan</li>
          <li className="list-group-item">Yanrui Li</li>
          <li className="list-group-item">Emily Nguyen</li>
          <li className="list-group-item">Akhil Yalamanchili</li>
          <li className="list-group-item">Anvith Neelam</li>
          <li className="list-group-item">Yuancheng Luo</li>
          <li className="list-group-item">Tanvi Gupta</li>
          <li className="list-group-item">Attrey Koul</li>
          <li className="list-group-item">Jade Lam</li>
          <li className="list-group-item">Vethaanthikaa Karthikeyan</li>
          <li className="list-group-item">Elie Ciril</li>
          <li className="list-group-item">Chris Huy Nguyen</li>
          <li className="list-group-item">Jyotirmay Meena</li>
          <li className="list-group-item">Bhavya Babbellapati</li>
          <li className="list-group-item">Elizabeth Vinokurov</li>
          <li className="list-group-item">Rayhan Roswendi</li>
          <li className="list-group-item">Chizorom Elleora Okoro</li>
          <li className="list-group-item">Syeda Zainab Fatima</li>
          <li className="list-group-item">Rishika Kurma</li>
          <li className="list-group-item">Jaelyn Jin</li>
          <li className="list-group-item">Diya Venkataragavan</li>
          <li className="list-group-item">Heshika Vaitheeswaran</li>
          <li className="list-group-item">Eshan Geadam</li>
          <li className="list-group-item">Prashasti Huliya</li>
          <li className="list-group-item">Namay Khandelwal</li>
          <li className="list-group-item">Ashika Sharma</li>
          <li className="list-group-item">Akhil Kolluru</li>
          <li className="list-group-item">Matthew Jun</li>
          <li className="list-group-item">Nyel Ahmed Uffa</li>
          <li className="list-group-item">Sahasra Yellepeddi</li>
          <li className="list-group-item">Sufyan Ahmed</li>
          <li className="list-group-item">Sharvani Mishra</li>
          <li className="list-group-item">Noor Gowra</li>
          <li className="list-group-item">Kenisha Verma</li>
          <li className="list-group-item">Zian Da</li>
          <li className="list-group-item">Zofia Rutkowska</li>
          <li className="list-group-item">Aryaman Gupta</li>
          <li className="list-group-item">Joonseong Lim</li>
          <li className="list-group-item">Anandhitha Arasu</li>
          <li className="list-group-item">Aaron Pinto</li>
          <li className="list-group-item">Sanwarya Ramani</li>
          <li className="list-group-item">Abhijay R Kodadala</li>
          <li className="list-group-item">Ashish Mulabagili</li>
          <li className="list-group-item">Benicio Olson</li>
          <li className="list-group-item">Aditya Yadav</li>
          <li className="list-group-item">Jahnavi Sharma</li>
          <li className="list-group-item">Sophie Huang</li>
          <li className="list-group-item">Aarushi Sharma</li>


        </ul>
      </div>
    </div>
  );
}

export default Winners;
