import React, { useState, useEffect } from 'react';
import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { motion, AnimatePresence } from 'framer-motion';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import db from './firebase';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StripePaymentButton = ({ 
    buyButtonId = 'buy_btn_1Qg5f3G2pmuJQ9KcE4z5ZSWK',
    publishableKey = 'pk_live_51Psa5GG2pmuJQ9KcIbAhhLtgqtenx65yzK8wklKuETm6mcpOdFcd3Qtn1dHUPMTB3VvoFFRstF7k9bLl34vyHShh00NBfhmHhW'
}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';
        script.async = true;
        document.body.appendChild(script);

        // Add custom styles for Stripe button
        const style = document.createElement('style');
        style.textContent = `
            stripe-buy-button {
                width: 100%;
                display: flex;
                justify-content: center;
            }
            stripe-buy-button > * {
                width: 100% !important;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.body.removeChild(script);
            document.head.removeChild(style);
        };
    }, []);

    return (
        <div className="w-full flex justify-center">
            <stripe-buy-button
                buy-button-id={buyButtonId}
                publishable-key={publishableKey}
            />
        </div>
    );
};


function Register() {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        school: '',
        grade: '',
        country: '',
        phone: '',
    });
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);

    const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      
    } else {
     
      setFile(null);
    }
  };

  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        const { name, school, grade, country } = formData;
    
        // Check if all required fields are filled
        if (name && school && grade && country) {
           
            // Check if a file is selected and its size is 5MB or less
                try {

                    // const url = await getDownloadURL(storageRef);
                    await db.collection("IRO-Register").add({
                        ...formData,
                        date: new Date(), 
                    });
                    setSuccessOpen(true);
                    window.location.href = "https://buy.stripe.com/4gweX28An1wkbcI28n"
                    setActiveStep(1);
                } catch (error) {
                    setErrorOpen(true);
                }
            
        } else {
            // Handle the case where required fields are missing
            setErrorOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSuccessOpen(false);
        setErrorOpen(false);
    };


    return (
        <div  style={{paddingTop: "50px"}} className="container">
            <div className="min-h-screen p-12 m-12 flex flex-col items-center justify-center">
                <Stepper style={{paddingBottom: "10px"}} activeStep={activeStep} className="w-full max-w-md mb-8">
                    <Step>
                        <StepLabel>Registration</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Payment</StepLabel>
                    </Step>
                </Stepper>

                <AnimatePresence mode="wait">
                    {activeStep === 0 ? (
                        <motion.div
                            key="registration"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <Box
                            style={{padding: "50px"}}
                                className="p-12 bg-white shadow-lg rounded-lg"
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <h1 className="text-xl font-bold text-center mb-6">IRO 2025 Registration</h1>

                                <p className="lowertitle">
                                    The International Research Olympiad (IRO) has a registration fee of $25 USD. The Opens and Semis round of the 2025 IRO are held online, with digital proctoring. The finals round is held in person in Cambridge, MA, with exams and testing held in the historic Harvard Square. As an academic organization, the International Research Olympiad does not earn profits from the competition, and registration fees are solely utilized for development of the IRO exam platform, marketing and advertisement, and contest expenses. Low-income students with tax documentation can upload eligible fee waivers in the below form.
                                    </p>

                                <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                                    <p className="secondarytitle">International Research Olympiad Eligibility Information</p>
                                </div>

                                <p className="lowertitle" data-aos="fade-up">
                                    To be eligible to compete, you must meet the below criteria:
                                    </p>

                                    <ul class="list-group list-group-flush">
                                    <li class="list-group-item"></li>
                                    <li class="list-group-item">Be enrolled in grades 6-12 or an equivalent homeschool program. <br/> This includes both traditional public and private high schools, as well as accredited online programs.</li>
                                    <br/><br/>
                                    </ul>

                                    <p className="lowertitle" data-aos="fade-up">
                                    If you meet the above criteria, great! You qualify to participate in the International Research Olympiad. Be sure to register for the competition below.
                                    </p>

                                    <div style={{paddingTop: "20px"}}></div>

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    value={formData.name}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="School"
                                    name="school"
                                    variant="outlined"
                                    value={formData.school}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Grade"
                                    name="grade"
                                    variant="outlined"
                                    value={formData.grade}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Country"
                                    name="country"
                                    variant="outlined"
                                    value={formData.country}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="phone"
                                    variant="outlined"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '0px' } }}
                                />

                                {/* <p style={{fontSize: "15px", marginTop: "20px"}}>(Optional) Upload PDF proof of reduced lunch, CommonApp fee waiver, or ACT/SAT waiver. <br/> Please name the file as <b>FIRSTNAME_LASTNAME.pdf</b> and ensure it's under <b>5MB</b>.</p>

                                <input
                                    style={{marginTop: "10px", marginBottom: "20px"}}
                                    type="file"
                                    className="hidden"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                                /> */}


                                <Button

                                    fullWidth
                                    variant="outlined"
                                    className="mt-12"
                                    onClick={handleSubmit}
                                    sx={{
                                        marginTop: '20px',
                                        borderColor: 'black',
                                        color: 'black',
                                        '&:hover': {
                                            borderColor: 'black',
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                >
                                    Continue to Payment
                                </Button>

                                <div style={{color: "gray", marginTop: "15px"}}>
                                <Typography  variant="caption" className="block mt-12 text-gray-100">
                                This registration does not constitute any financial commitment or contractual obligation. {' '}
                                 </Typography>
                                 <br/>
                                 <Typography  variant="caption" className="block mt-12 text-gray-100">
                                     If you qualify for a fee waiver, please fill out this <a href="/fee-waiver-form">form</a> instead.
                                 </Typography>
                               
                                <br/>
                               
                                </div>
                            </Box>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="payment"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="w-full max-w-md"
                        >
                            <Box className="p-12 bg-white shadow-lg rounded-lg">
                                <h2 style={{paddingTop: "20px"}} className="text-xl font-bold text-center mb-6">Complete Your Payment</h2>
                                <div style={{padding: "40px"}} className="min-h-screen p-12 m-12 flex flex-col items-center justify-center">
                                  <StripePaymentButton />
                                </div>
                                 <div style={{textAlign: "center", color: "gray", paddingBottom: "20px"}} className="container">
                               
                                </div>
                            </Box>
                            {/* <Box className="p-12 bg-white shadow-lg rounded-lg">
                                <h2 tyle={{paddingTop: "50px"}} className="text-xl font-bold text-center mb-6">Complete Your Payment</h2>
                                <div className="w-full flex justify-center items-center">
                                    <StripePaymentButton />
                                </div>
                            </Box> */}
                        </motion.div>
                    )}
                </AnimatePresence>

                <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Registration successful! Proceeding to payment...
                    </Alert>
                </Snackbar>

                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        Please fill required fields.
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default Register;
