import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  CardMedia, 
  Grid, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  IconButton,
  Box,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';



// Sample blog data
const blogPosts = [
  {
    id: 1,
    title: " Empowering the Next Generation of Researchers",
    excerpt: "This year, we are thrilled to welcome Algoverse Academy as an IRO sponsor. Their enthusiasm for AI research and education makes them an ideal partner for our objective to develop the next generation of innovators.",
    content: `
    <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
  <h1 style="color: #2a4d7f;">Empowering the Next Generation of Researchers</h1>
  <p>The International Research Olympiad (IRO) hopes to give students a forum to demonstrate their research abilities, meet like-minded individuals, and achieve international recognition for their innovative ideas. Every year, we bring together young minds interested in research and scientific inquiry, giving them the opportunity to solve real-world challenges through interdisciplinary research.</p>
  
  <p>This year, we are thrilled to welcome Algoverse Academy as an IRO sponsor. Their enthusiasm for AI research and education makes them an ideal partner for our objective to develop the next generation of innovators. With this support, we intend to give students greater opportunities to participate in AI research, build technical skills, and learn from industry specialists.</p>

  <h2 style="color: #2a4d7f;">Why Algoverse Academy is Supporting IRO</h2>
  <p>Algoverse Academy acknowledges the importance of experiential and competitive learning in building tomorrow's leaders. The International Research Olympiad is a unique program that allows students to demonstrate their research skills, interact with like-minded individuals, and receive international recognition for their innovative ideas.</p>
  
  <p>Their sponsorship of the IRO stems from their commitment to:</p>
  <ul style="margin-left: 20px;">
    <li>Helping Research-Based Education: They assist student research outside of the classroom by providing resources from the IRO.</li>
    <li>Closing the gap between academia and industry: Students struggle to apply scholarly ideas in real-world situations. Students who participate in the IRO have the opportunity to work on research that has real-world applications, which is of major value at the Algoverse Academy.</li>
    <li>Creating a Global Family of Innovators: With attendees from all over the world, the Olympiad is a melting pot of ideas, cultures, and collaboration. They also aim to facilitate cross-border knowledge transmission and technological exploration.</li>
    <li>Providing Access to Elite Resources: Their goal is to provide equal access to elite resources for all students, regardless of background. Supporting the IRO allows more students to benefit from our elite-level resources and mentorship programs.</li>
  </ul>

  <p>The International Research Olympiad serves as a springboard for research enthusiasts looking for placements, scholarships, and professional networking opportunities. Being part of the IRO is how they’re doubling down on developing the next generation of great minds and powering the next generation of breakthroughs.</p>

  <h2 style="color: #2a4d7f;">Winners of the 2024 International Research Olympiad</h2>

  <h3 style="color: #2a4d7f;">What is Algoverse Academy?</h3>
  <p>Algoverse Academy is a cutting-edge online portal for ambitious students seeking to excel in computational research, artificial intelligence, and data science. Our challenging program exposes students to real-world experience, mentorship from the finest minds, and an active curriculum, all with the goal of developing researchers.</p>
  
  <h4 style="color: #2a4d7f;">AI Research Program</h4>
  <p>Our AI Research Program is a 12-week online experience that immerses students in real-world research. Participants engage in:</p>
  <ul style="margin-left: 20px;">
    <li>Literature review and ideation</li>
    <li>Developing and implementing ML algorithms</li>
    <li>Communicating research results through publications</li>
    <li>Submitting research to conferences</li>
  </ul>

  <h4 style="color: #2a4d7f;">Spring 2025 Sessions:</h4>
  <p><strong>Cohort A:</strong> Jan. 26 - Apr. 20 (Lecture: Sunday, 1:00 - 2:30 pm PT)</p>
  <p><strong>Cohort B:</strong> Feb. 23 - May 18 (Lecture: Sunday, 10:00 - 11:30 am PT)</p>
  <p><strong>Extended Deadline for Cohort B:</strong> Apply by Wednesday, Feb. 19th, 11:59 pm PT.</p>

  <h4 style="color: #2a4d7f;">Summer 2025 Sessions:</h4>
  <p>Multiple cohorts with flexible schedules, including weekend lectures.</p>

  <h4 style="color: #2a4d7f;">Program Features:</h4>
  <ul style="margin-left: 20px;">
    <li>Small Project Groups: Teams of 3-4 students with dedicated mentorship</li>
    <li>Expert Mentorship: Guidance through literature review, coding, model training, and manuscript drafting.</li>
    <li>Flexible Weekly Commitment: 5-10 hours per week, with optional office hours</li>
  </ul>

  <p style="font-weight: bold;">Explore Programs & Pricing Here</p>
  <p>Their goal is to give all of their students, regardless of background, an equal opportunity to get a great education. In addition, they offer funding help and various scholarship programs to deserving students to keep their program as affordable as feasible.</p>

  <h2 style="color: #2a4d7f;">What is the International Research Olympiad?</h2>
  <p>The International Research Olympiad (IRO) is a global competition dedicated to testing science research ability and developing critical thinking and analytical skills. The IRO covers a variety of subjects, including social studies, engineering, and physics. Last year, the IRO proudly welcomed over 4,000 students from 30+ nations and hosted a fully funded finals experience in Harvard Square, Cambridge, Massachusetts. The IRO helps students lay a solid basis for future success in any research subject by cultivating creativity, critical thinking, and a love of discovery. It’s a great way for high school students to develop important research skills!</p>

  <h3 style="color: #2a4d7f;">What Makes IRO Unique?</h3>
  <p>The International Research Olympiad (IRO) is dedicated to improving student researchers' analytical and critical thinking skills, which are necessary in the field of scientific discovery. The IRO encourages extensive research and skill development, rather than simply memorizing formulas. The 2025 IRO assessments put practical knowledge and big-picture thinking to the test, allowing students to leave with a stronger desire to pursue scientific inquiry.</p>

  <blockquote style="border-left: 3px solid #2a4d7f; padding-left: 10px; margin-left: 20px;">
    <p><em>Rishab Kumar Jain, President and Chair of the Student Board of Advisors to the International Research Olympiad said, "What sets the IRO apart is our commitment to critical thinking, which is essential for scientific research. We have seen a high number of contestants who possess extraordinary potential and who are serious and motivated about their research-based, real-world interests."</em></p>
  </blockquote>

  <h2 style="color: #2a4d7f;">Why Should You Participate?</h2>
  <p>Whether you're a student looking to push the boundaries, a researcher looking to establish new paths, or an entrepreneur looking to make a name for yourself, the IRO is the event for you. In conjunction with the world-renowned Algoverse Academy, this year's event will be even bigger and more entertaining, with even more opportunities for students to obtain vital experience.</p>
  
  <h4 style="color: #2a4d7f;">Here’s What You Can Expect:</h4>
  <ul style="margin-left: 20px;">
    <li>High-level competition with top-tier students and mentors.</li>
    <li>Exposure to global research trends in AI, robotics, and computational sciences.</li>
    <li>Opportunities to collaborate with peers and industry experts.</li>
    <li>A chance to win scholarships and exclusive access to many programs.</li>
    <li>A fully funded trip to Harvard for the Finals round.</li>
  </ul>

  <p>Many IRO participants have gone on to attend top institutions, secure prominent internships, and launch research projects with real-world impact. This is your opportunity to be a part of something groundbreaking.</p>

  <h2 style="color: #2a4d7f;">Ready to Take the Leap?</h2>
  <p>At the International Research Olympiad, we believe in providing a competitive yet collaborative environment for students to showcase their research abilities. Sponsored by Algoverse Academy, we are now providing an even more enriched experience that ties conceptual knowledge to practical application.</p>

  <p>If you want to be involved in research and innovation, we encourage you to join the IRO and explore the exciting programs at Algoverse Academy.</p>

  <p><a href="https://www.internationalresearcholympiad.org/" style="color: #0077cc; text-decoration: none;">Register for IRO Now</a> &nbsp; | &nbsp; <a href="https://algoverseairesearch.org/" style="color: #0077cc; text-decoration: none;">Learn More About Algoverse Academy</a></p>

  <p>Join us on this journey to innovation and discovery—because the future belongs to those who dare to explore it.</p>

</div>


    `,
    author: "IRO Team",
    date: "March 25, 2025",
    readTime: "5 min",
    image: "https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%2011.22.07%E2%80%AFAM-LtnHatOUArrKkX33o1OLshpFq0mcNd.png"
  },
  {
    id: 2,
    title: "Unlocking Innovation with Inspirit AI: A Perfect Path for IRO Participants",
    excerpt: "We are excited to announce our partnership with Inspirit AI, a program established by MIT and Stanford alumni to provide students around the world with state-of-the-art artificial intelligence (AI) education.",
    content: `
    <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
  <h1 style="text-align: center; color: #2a5d84;">Unlocking Innovation with Inspirit AI: A Perfect Path for IRO Participants</h1>

  <p>Empowering the upcoming generation of leaders, innovators, and thinkers has always been a goal of the International Research Olympiad (IRO). Students from more than 45 nations gather on its global platform to present their research, work with classmates, and find new academic interests. We are excited to announce our partnership with Inspirit AI, a program established by MIT and Stanford alumni to provide students around the world with state-of-the-art artificial intelligence (AI) education.</p>

  <p>Inspirit AI presents a fantastic opportunity for IRO participants—a chance to learn more about technology and discover its practical uses. This program might be the next phase of your academic career if you have a strong desire to conduct research, solve problems, and have an influence.</p>

  <p>Because both organizations have the same primary goal of fostering young people's intellectual curiosity and giving them the tools they need to tackle real-world problems, the IRO and Inspirit AI's partnership makes perfect sense. While Inspirit AI offers the technical support and mentoring necessary to increase the impact of such research, the IRO is committed to encouraging students to participate in creative research across disciplines. We anticipate that this partnership will encourage even more students to investigate the ways in which technology can be used to resolve challenging issues and promote global change.</p>

  <h2 style="color: #2a5d84;">What is Inspirit AI?</h2>

  <p>An educational program called Inspirit AI gives pre-college students practical experience with artificial intelligence. The program, created and taught by AI specialists and Stanford and MIT alums, gives students technical skills while enticing them to investigate the ethical and societal ramifications of AI.</p>

  <p>Inspirit AI offers two primary programs:</p>

  <ul>
    <li><strong>Inspirit AI Scholars:</strong> Students enrolled in this program learn about the foundations of artificial intelligence, such as computer vision, neural networks, and machine learning. Students use these ideas to work on practical projects like interpreting data on climate change or identifying medical disorders from X-rays. Anyone with a love of learning and creativity can use it because it is made for students with little to no prior coding experience.</li>
    <li><strong>Inspirit AI Fellows:</strong> This advanced program delves deeper into AI algorithms and research methodologies and is designed for students who have previously coded. Participants create independent research projects in close collaboration with mentors. In addition to improving their technical proficiency, fellows cultivate the critical thinking and problem-solving abilities necessary for carrying out significant research.</li>
  </ul>

  <p>The ethical issues surrounding AI are emphasized heavily in Inspirit AI in addition to the core curriculum. In order to ensure that social responsibility and technological advancement go hand in hand, students are urged to consider critically how their projects might affect society.</p>

  <p>Learn more about Inspirit AI’s programs here: <a href="https://www.inspiritai.com/" target="_blank" style="color: #2a5d84;">Inspirit AI</a></p>

  <h2 style="color: #2a5d84;">Why IRO Participants Should Consider Inspirit AI</h2>

  <p>Inspirit AI is more than just a coding boot camp for students who are enthusiastic about research; it's a springboard for worthwhile projects and future opportunities. IRO participants should think about enrolling for the following reasons:</p>

  <ul>
    <li><strong>Develop Your Research Skills:</strong> Inspirit AI supports the IRO's mission of promoting excellence in research by instructing students on how to use AI to tackle challenging issues. Regardless of your field of study—environmental science, health, or astrophysics—AI can help your project reach new heights. By incorporating AI tools into their current research interests, participants learn how to approach problems from a multidisciplinary perspective.</li>
    <li><strong>Collaborate with Professionals:</strong> Students receive direct instruction from professors connected to MIT, Stanford, and other prestigious universities. Along with technical advice, this mentorship offers insights into potential career and academic paths. Students get a peek into the working world of AI and technology as instructors share their experiences in state-of-the-art research labs.</li>
    <li><strong>Create a Competitive Portfolio:</strong> The projects you finish with Inspirit AI will make an impression whether you're applying to colleges, internships, or upcoming research contests like IRO. Students frequently win prestigious awards, present at conferences, and publish their work. Inspirit AI gives you the framework and assistance you need to transform concepts into real results, improving your academic standing and demonstrating your dedication to creativity.</li>
    <li><strong>International Collaboration:</strong> Inspirit AI links students all over the world, much like the IRO. Along with working with peers who are as passionate about innovation as you are, you will be exposed to a variety of viewpoints. This global network encourages intercultural understanding and equips students to work together to address global issues.</li>
    <li><strong>Impact on the Real World:</strong> Students are encouraged to work on projects that tackle real-world problems by Inspirit AI. Participants create solutions outside of the classroom for issues like health care and climate change, showcasing AI's ability to promote progress. This is a perfect fit with the IRO's mission to support impactful research.</li>
  </ul>

  <h2 style="color: #2a5d84;">Program Details and How to Apply</h2>

  <p>Inspirit AI offers both in-person and virtual programs to accommodate students worldwide. The program typically runs in summer and winter sessions, with flexible schedules for students balancing school and extracurricular commitments.</p>

  <p><strong>Program Costs:</strong></p>
  <ul>
    <li><strong>Inspirit AI Scholars:</strong> $900 USD (virtual), $1,800 USD (in-person)</li>
    <li><strong>Inspirit AI Fellows:</strong> $1,200 USD (virtual), $2,000 USD (in-person)</li>
  </ul>

  <p>The cost of the program reflects the high-quality instruction, mentorship, and resources provided. Scholarships are available for students who demonstrate financial need, ensuring that talented students from all backgrounds can participate.</p>

  <p>Apply now for Inspirit AI's upcoming session: <a href="https://inspiritai.paperform.co/" target="_blank" style="color: #2a5d84;">Inspirit AI Application</a></p>

  <h2 style="color: #2a5d84;">Read Success Stories</h2>
  <p>These achievements demonstrate how well the IRO and Inspirit AI work together. Both platforms enable students to think creatively, push boundaries, and turn concepts into worthwhile projects. Students who take part in both the IRO and Inspirit AI get a well-rounded education that blends academic understanding with real-world application.</p>

  <p>Read success stories here: <a href="https://www.inspiritai.com/inspirit-ai-alumni-journeys" target="_blank" style="color: #2a5d84;">Inspirit AI Alumni Journeys</a></p>

  <h2 style="color: #2a5d84;">How to Get Started</h2>

  <p>Enrolling in Inspirit AI is straightforward:</p>

  <ol>
    <li>Visit the Inspirit AI website: <a href="https://www.inspiritai.com/" target="_blank" style="color: #2a5d84;">Inspirit AI</a></li>
    <li>Choose your preferred program (Scholars or Fellows).</li>
    <li>Complete the online application form.</li>
    <li>Submit a short statement about your interests and goals.</li>
    <li>Upon acceptance, you’ll receive details about your session and project options.</li>
  </ol>

  <p><strong>Early applications are encouraged, as spots fill up quickly.</strong></p>

  <h2 style="color: #2a5d84;">A Partnership Built on Innovation</h2>

  <p>We at the International Research Olympiad are committed to giving students the resources they need to realize their ideas. This dedication is reflected in our collaboration with Inspirit AI. We are creating new opportunities for young innovators worldwide by fusing the spirit of international research with top-notch AI education.</p>

  <p>In addition to helping the IRO, Inspirit AI's sponsorship enhances the event for attendees. It illustrates Inspirit AI's commitment to developing the upcoming generation of innovators, scientists, and technologists. This partnership levels the playing field and guarantees that talent, not circumstance, determines success by giving students access to resources that might otherwise be unattainable.</p>

  <p>This collaboration is an investment in the future rather than merely a sponsorship. Inspirit AI and the IRO both envision a future in which young brains are equipped to take on tomorrow's challenges. Together, we're creating a community of thinkers, learners, and changemakers prepared to influence the future.</p>
</div>

     
    `,
    author: "IRO Team",
    date: "March 18, 2025",
    readTime: "7 min",
    image: "https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%2012.02.55%E2%80%AFPM-G7cEcowxWTIRMfBXCaN9NubGdw4XUU.png"
  },
  {
    id: 3,
    title: "SPOTLIGHT: LUMIERE EDUCATION SPONSORSHIP",
    excerpt: "Through this partnership, students get access to top-tier research mentorship and a chance to compete in a global academic Olympiad designed to test their critical thinking and problem-solving abilities.",
    content: `

    <body style="font-family: 'Roboto', Arial, sans-serif; line-height: 1.6; color: #333; max-width: 800px; margin: 0 auto; padding: 20px;">
    <header style="text-align: center; margin-bottom: 30px;">
        <h1 style="color: #1976d2; font-size: 32px; margin-bottom: 10px;">SPOTLIGHT: LUMIERE EDUCATION SPONSORSHIP</h1>
        <h2 style="color: #555; font-size: 20px; font-weight: normal; margin-top: 0;">Exploring the IRO x Lumiere Education Sponsorship and How It's Empowering the Next Generation of Scholars</h2>
    </header>

    <main>
        <p style="font-size: 16px; margin-bottom: 20px;">
            If you're a high school student passionate about research and eager to challenge
            yourself academically, you might be wondering: How can I push my skills to the next
            level?
        </p>

        <p style="font-size: 16px; margin-bottom: 20px;">
            That's exactly where Lumiere Education and the
            International Research Olympiad (IRO) come in. Through this
            partnership, students get access to top-tier research
            mentorship and a chance to compete in a global academic
            Olympiad designed to test their critical thinking and
            problem-solving abilities.
        </p>

        <div style="background-color: #f5f8ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h3 style="color: #1976d2; margin-top: 0;">About Lumiere Education</h3>
            <p style="margin-bottom: 0;">
                Lumiere Education was founded by researchers from
                Harvard and Oxford with a simple but ambitious goal: to bring
                rigorous, university-level research opportunities to high school
                students worldwide.
            </p>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            In many ways, research is the ultimate academic challenge. It's not about
            memorizing formulas or writing essays—it's about asking original questions, thinking
            critically, and engaging with knowledge in a way that's truly your own. Lumiere
            Education was founded by Harvard and
            Oxford researchers who recognized that high
            school students rarely get the chance to
            engage in real research, despite having the
            talent and curiosity to do so.
        </p>

        <div style="display: flex; margin: 30px 0;">
            <div style="flex: 1; width: 300px; height: auto">
                <img src="https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%203.52.05%E2%80%AFPM-fIePCsUihUNjm3CwQ3F6bW1Pkr9Yhi.png" alt="Student conducting research" style="max-width: 100%; border-radius: 8px;">
            </div>
            <div style="flex: 1; padding-left: 20px;">
                <p>
                    Through Lumiere's programs, students work
                    one-on-one with Ph.D. mentors from top
                    universities to explore a subject they're
                    passionate about, conduct original research,
                    and produce a high-quality academic paper.
                    This isn't just a classroom assignment! It's
                    independent, rigorous, and designed to
                    challenge students to think beyond the high
                    school level.
                </p>
            </div>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            One of the most popular programs is the Lumiere Research Scholar Program, a
            12-week mentorship experience where students develop and complete their own
            research project. Those who are just starting their research journey can opt for
            Lumiere's group-based research program, while students who have already completed a
            Lumiere program can apply for the Lumiere Research Fellowship, which offers an even
            more advanced level of mentorship.
        </p>

        <p style="font-size: 16px; margin-bottom: 20px;">
            These programs aren't just about research for the sake of research. Many students
            go on to publish their work in academic journals, submit it to prestigious competitions,
            or use it as a foundation for future studies. And beyond the tangible outcomes, there's
            something even more valuable—the confidence that comes from knowing you can
            engage with complex ideas, contribute to academic conversations, and think like a
            scholar.
        </p>

        <p style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">
            📌 Learn more: <a href="https://www.lumiere-education.com/" style="color: #1976d2; text-decoration: none;">https://www.lumiere-education.com/</a>
        </p>

        <div style="background-color: #f5f8ff; padding: 20px; border-radius: 8px; margin: 30px 0; display: flex; align-items: center;">
            <div style="flex: 2;">
                <h3 style="color: #1976d2; margin-top: 0;">Lumiere's Sponsorship with the IRO</h3>
                <p style="margin-bottom: 0;">
                    Lumiere doesn't just offer research
                    opportunities; it's also deeply invested in supporting
                    students who want to push their academic
                    boundaries in different ways. That's why it has
                    partnered with the International Research Olympiad
                    (IRO), an exam-based competition designed to test
                    students on their ability to apply knowledge, analyze
                    problems, and think critically about real-world
                    issues.
                </p>
            </div>
            <div style="flex: 1; text-align: center;">
                <img src="https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%203.52.51%E2%80%AFPM-OLsoiMC5BiQtlBW62vaXukD1Z4QP8G.png" alt="IRO Logo" style="max-width: 100%; border-radius: 8px;">
            </div>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            The IRO isn't a memorization-based test. It's designed to reflect the way research
            actually works; by challenging students to approach questions from multiple angles, use
            evidence to support their reasoning, and think across disciplines. Many Lumiere
            students, who are already accustomed to working through
            complex problems in their research, find that the IRO
            provides an excellent opportunity to test their skills in a
            competitive setting.
        </p>

        <div style="background-color: #eaf5ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h4 style="color: #1976d2; margin-top: 0;">Why did Lumiere choose to sponsor the IRO?</h4>
            <ul style="padding-left: 20px;">
                <li style="margin-bottom: 10px;">First, a shared mission! Both Lumiere and the IRO believe that
                research should be accessible to ambitious students, no
                matter where they're from.</li>
                <li style="margin-bottom: 10px;">Moreover, Lumiere seeks to
                empower young scholars, as their mentorship model aligns perfectly with IRO's goal of
                giving students a platform to showcase their work.</li>
                <li>They have created a pipeline for top
                researchers – Many IRO participants go on to complete Lumiere programs, using their
                competition experience as a launchpad for deeper research.</li>
            </ul>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            Through this partnership, Lumiere is helping to expand access to the IRO and
            encourage more students to take on the challenge. The sponsorship reflects a shared
            mission: to make advanced academic opportunities available to more students,
            regardless of background, and to create a community of young scholars who are ready to
            engage with knowledge in a deep and meaningful way.
        </p>

        <p style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">
            📌 Learn more: <a href="https://www.internationalresearcholympiad.org/" style="color: #1976d2; text-decoration: none;">https://www.internationalresearcholympiad.org/</a>
        </p>

        <div style="background-color: #f5f8ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h3 style="color: #1976d2; margin-top: 0;">Lumiere's Research Scholars Program</h3>
            <p>
                At first glance, research and Olympiads might seem like two very different paths.
                One involves long-term projects and deep dives into a single topic, while the other is a
                high-intensity exam that requires quick thinking and adaptability. But in reality, the
                skills that make great researchers also make great
                Olympiad competitors.
            </p>
        </div>

        <div style="display: flex; margin: 30px 0; align-items: center;">
            <div style="flex: 1; padding-right: 20px;">
                <p>
                    Many IRO participants have also been Lumiere
                    Research Scholars. They develop world-class mentorship
                    by working one-on-one with Ph.D. researchers from
                    institutions like Harvard, MIT, and Stanford. You develop
                    a topic that interests you and produce a high-quality
                    research paper, potentially even resulting in a
                    publication. Get the opportunity to publish your work or
                    submit it to research competitions like the IRO.
                </p>
            </div>
           
        </div>

        <blockquote style="border-left: 4px solid #1976d2; padding-left: 20px; margin: 30px 0; font-style: italic; color: #555;">
            <p>
                "Our students (we call them Lumiere Research
                Scholars) represent the top of academic excellence and
                drive. They are the world's future scholars.
            </p>
            <p>
                The programs we offer are the Individual Research
                Program (12 weeks; 2990 USD), the Premium Research &
                Publication Program (16-20 weeks; 5900 USD), the
                Research Fellowship (6-12 months, 8900 USD). You will be
                working 1-1 with a top PhD candidate.
            </p>
            <p>
                We also offer the Professor Premium Publication
                Program, in which you work with a professor 1-1 (16-20
                weeks; 8900 USD)."
            </p>
            <footer style="font-style: normal; margin-top: 10px;">From Lumiere Education's website</footer>
        </blockquote>

        <p style="font-size: 16px; margin-bottom: 20px;">
            Lumiere students have gone on to present at
            international conferences, co-author papers with their
            mentors, and gain recognition in prestigious
            competitions.
        </p>

        <p style="font-size: 16px; margin-bottom: 20px;">
            If you're considering competing in the IRO, completing a Lumiere program first
            can give you an edge—helping you refine your research, gain feedback from top
            mentors, and ensure your project is competition-ready.
        </p>

        <p style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">
            📌 Apply to Lumiere: <a href="https://airtable.com/appK9HemdsQBzVefU/shrWKcNSHjd4V2veV" style="color: #1976d2; text-decoration: none;">https://airtable.com/appK9HemdsQBzVefU/shrWKcNSHjd4V2veV</a>
        </p>

        <div style="background-color: #f5f8ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h3 style="color: #1976d2; margin-top: 0;">What to Expect From the IRO</h3>
            <p style="margin-bottom: 10px;">See the finals event from last year's IRO here!</p>
            <div style="text-align: center; margin: 20px 0; width: 600px; height: auto">
                <img src="https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%203.54.38%E2%80%AFPM-PwC7iwvKkaUi66LLI2cZAgkGNQr0U7.png" alt="International Research Olympiad Finals Event" style="max-width: 100%; border-radius: 8px;">
            </div>
            <p>
                Unlike research competitions that focus on
                project submissions, the IRO is a written Olympiad
                exam. It's designed for students who want to
                challenge themselves with complex, research-based
                questions across multiple disciplines.
            </p>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            The Olympiad is open to high school students
            from around the world and covers a range of subjects, including science, math,
            economics, and social sciences. The questions are
            designed to reflect real-world academic
            challenges, requiring students to analyze data,
            interpret findings, and apply critical thinking
            skills rather than simply recalling information.
        </p>

        <p style="font-size: 16px; margin-bottom: 20px;">
            Competing in the IRO is an excellent way to
            demonstrate intellectual curiosity,
            problem-solving ability, and the ability to think
            independently—all of which are qualities that
            stand out on college applications. For students
            who enjoy academic challenges and want to put their knowledge to the test, it's an
            opportunity worth considering.
        </p>

        <div style="background-color: #eaf5ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h4 style="color: #1976d2; margin-top: 0;">Who Can Participate?</h4>
            <ul style="padding-left: 20px;">
                <li style="margin-bottom: 10px;">High school students from any country aged 13-18</li>
                <li style="margin-bottom: 10px;">Those interested in STEM, social sciences, and interdisciplinary problem-solving</li>
                <li>Students looking for a challenging, research-based Olympiad experience</li>
            </ul>
        </div>

        <div style="background-color: #eaf5ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <h4 style="color: #1976d2; margin-top: 0;">Why Compete in the IRO?</h4>
            <ul style="padding-left: 20px;">
                <li style="margin-bottom: 10px;"><strong>Stand out in college applications!</strong> Olympiad achievements demonstrate
                intellectual curiosity, problem-solving ability, and academic rigor.</li>
                <li style="margin-bottom: 10px;"><strong>Tackle real-world problems!</strong> The IRO challenges students to apply their knowledge
                to complex, research-based questions across disciplines.</li>
                <li><strong>Join a global network!</strong> Become part of an international academic community,
                because no matter where you rank, the connections you make will endure.</li>
            </ul>
        </div>

        <p style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">
            📌 Register for the IRO: <a href="https://www.internationalresearcholympiad.org/register" style="color: #1976d2; text-decoration: none;">https://www.internationalresearcholympiad.org/register</a>
        </p>

        <div style="background-color: #f5f8ff; padding: 20px; border-radius: 8px; margin: 30px 0; display: flex; align-items: center;">
            <div style="flex: 2;">
                <h3 style="color: #1976d2; margin-top: 0;">Ready to Take the Next Step?</h3>
                <p>
                    For students who are serious about academics,
                    Lumiere and the IRO offer two unique but complementary
                    opportunities. One allows you to explore a subject deeply
                    through research, while the other tests your ability to apply
                    knowledge in a competitive, fast-paced setting. Both
                    experiences push you beyond the typical high school
                    curriculum and help you develop the kind of intellectual
                    independence that top universities value.
                </p>
            </div>
            <div style="flex: 1; text-align: center; padding: '20px'">
                <img src="https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%203.59.03%E2%80%AFPM-UW2YkxwpVo2U4GDnFtF2xQGZ65fWfG.png" alt="IRO Preparation Textbook" style="max-width: 100%; border-radius: 8px;">
            </div>
        </div>

        <p style="font-size: 16px; margin-bottom: 20px;">
            If you're someone who loves diving into big ideas,
            applying to Lumiere or competing in the IRO could be one
            of the most rewarding academic experiences you take on in
            high school. For more information, visit each website and
            purchase the IRO Official Preparation Textbook to get
            started on your studying today. If your school doesn't already have a research club,
            consider starting one; it's a great way to dive deeper into research and connect with
            like-minded peers!
        </p>

        <div style="background-color: #f0f7ff; padding: 20px; border-radius: 8px; margin: 30px 0;">
            <ul style="list-style-type: none; padding-left: 0;">
                <li style="margin-bottom: 15px;">
                    <p style="margin: 0;"><span style="font-size: 18px;">🚀</span> <strong>Apply to Lumiere today to start your research journey.</strong></p>
                    <p style="margin: 5px 0 0 25px;"><a href="https://www.lumiere-education.com/" style="color: #1976d2; text-decoration: none;">https://www.lumiere-education.com/</a></p>
                </li>
                <li>
                    <p style="margin: 0;"><span style="font-size: 18px;">🌍</span> <strong>Sign up for the IRO and put your problem-solving skills to the test.</strong></p>
                    <p style="margin: 5px 0 0 25px;"><a href="https://www.internationalresearcholympiad.org/" style="color: #1976d2; text-decoration: none;">https://www.internationalresearcholympiad.org/</a></p>
                </li>
            </ul>
        </div>
    </main>

    <footer style="margin-top: 50px; padding-top: 20px; border-top: 1px solid #eee; text-align: center; color: #777; font-size: 14px;">
        <p>&copy; 2025 International Research Olympiad. All rights reserved.</p>
        <p>A partnership between IRO and Lumiere Education</p>
    </footer>
</body>
    
    `,
    author: "IRO Staff",
    date: "March 10, 2025",
    readTime: "8 min",
    image: "https://h2rsi9anqnqbkvkf.public.blob.vercel-storage.com/Screenshot%202025-04-01%20at%203.50.53%E2%80%AFPM-iVpCedIyjgjcBBzO8H5xSAQwIvsVWa.png"
  }
];



function BlogModal({ open, handleClose, post }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  if (!post) return null;
  
  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="xl"  // Changed from "md" to "xl" for maximum width
      fullWidth      // Ensures the dialog takes up the full width
      PaperProps={{
        sx: {
          width: '95vw',  // 95% of viewport width
          maxWidth: '1800px',  // Maximum absolute width
          height: '90vh',  // 90% of viewport height
          m: 'auto',  // Centers the dialog
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{post.title}</Typography>
          <IconButton onClick={handleClose} edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CardMedia
              component="img"
              height="400"  // Increased from 300 to 400
              image={post.image}
              alt={post.title}
              sx={{ borderRadius: 1, objectFit: 'cover' }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mb={2} gap={3}>
              <Box display="flex" alignItems="center">
                <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2" color="text.secondary">
                  {post.author}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2" color="text.secondary">
                  {post.readTime} read
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {post.date}
              </Typography>
            </Box>
            
            <Divider sx={{ mb: 4 }} />
            
            <Box sx={{ 
              '& h2': { 
                fontSize: '2rem', 
                mb: 3, 
                mt: 2 
              },
              '& h3': { 
                fontSize: '1.5rem', 
                mb: 2, 
                mt: 3,
                color: 'primary.main' 
              },
              '& h4': { 
                fontSize: '1.25rem', 
                mb: 1, 
                mt: 2,
                fontWeight: 'bold' 
              },
              '& p': { 
                mb: 2,
                lineHeight: 1.7
              },
              '& ul, & ol': { 
                mb: 3,
                pl: 4 
              },
              '& li': { 
                mb: 1
              },
              '& pre': {
                backgroundColor: 'grey.100',
                p: 2,
                borderRadius: 1,
                mb: 3,
                overflow: 'auto'
              }
            }}>
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Blog() {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleClickOpen = (post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box textAlign="center" mb={6}>
      <p  className="maintitle bigblueonthewing">IRO Blog</p>

        <Typography variant="h5" color="text.secondary" paragraph>
          Insights, tutorials, and updates from our team
        </Typography>
      </Box>
      
      <Grid container spacing={4}>
        {blogPosts.map((post) => (
          <Grid item key={post.id} xs={12} sm={6} md={4}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={post.image}
                alt={post.title}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {post.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {post.excerpt}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="caption" color="text.secondary">
                    {post.date}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {post.readTime} read
                  </Typography>
                </Box>
              </CardContent>
              <Box px={2} pb={2}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  onClick={() => handleClickOpen(post)}
                >
                  Read More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <BlogModal 
        open={open} 
        handleClose={handleClose} 
        post={selectedPost} 
      />
    </Container>
  );
}

export default Blog;
