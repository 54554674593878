import React from 'react'
import './App.css'
import LazyLoad from 'react-lazyload';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import logo from './smallerspinner.png'; 
import finalsharvard from "./finalist_photos/finalsharvard-min.webp"
import ReactCurvedText from 'react-curved-text';
import Marquee from "react-fast-marquee";
import XIcon from '@mui/icons-material/X';
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

function Footer() {

  const handleClick = () => {
    window.location.href = "https://www.zeffy.com/donation-form/international-research-olympiad-parent-organization"
    
  }


  return (
    <div>
    
      <div className='container'>
        <div className='row rowpad2'  style={{zIndex: '999'}}>

          {/* <div className="Marquee-css-div">
            <div className="shadow-div"/>
            <div className="shadow-div-two"/>
            <Marquee speed={100} direction="right">
              <div className="nohoverimage"><span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span> <span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span> <span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span> <span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span> <span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span> <span className="Marquee-css-image"><img width="120px" src="https://lh3.googleusercontent.com/8NQJXUp0WaAWvFYd3Po0u7R2dtjxPPxRClL0LT4KOwOmUloD8HmZzIDvyGz4P-Sf_kbysxV_CzA7bh0ofEFCKSM=w16383"/></span></div>
            </Marquee>
          </div> */}


          <div className="footercool">

          <div className='row align-items-center'>

            <div class="clearfix">
              <LazyLoad height={200} offset={100} once>
                  <img style={{width: "400px", border: "thick double #7d9ad4"}} src={finalsharvard} className="foot-finals-pic col-md-6 float-md-end mb-3 ms-md-3"/>
              </LazyLoad>
              <p className="maintitleee">Our Mission</p>
                  <p className="lowertitleee" style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>

                    Designed to inspire and challenge students aged 13-18 around the globe, the IRO aims to cultivate many essential qualities of the research process. Unlike traditional science olympiads
                    that delve into specific subjects, the IRO encourages students to build skills that will be useful in any research discipline. Through this, participants foster a deeper understanding of the scientific process and build
                    overall creativity, critical thinking, and analysis skills, forming a foundation for later success.
                      
                  </p>
            </div>
               
               </div>

         
          </div>
          <div className="footercooll">
            <p className="maintitleee">Our Mission</p>
            <p className="lowertitleee" style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>

              Designed to inspire and challenge students aged 13-18 around the globe, the IRO aims to cultivate many essential qualities of the research process. Unlike traditional science olympiads
              that delve into specific subjects, the IRO encourages students to build skills that will be useful in any research discipline. Through this, participants foster a deeper understanding of the scientific process and build
              overall creativity, critical thinking, and analysis skills, forming a foundation for later success.
                
            </p>
            
          </div>

          <nav class="navbar bg-body-tertiary" >

            <div className="curved-text-container">
             
              <img src={logo} width="90px" alt="Logo" className="circle-image" />
            </div>

            <div class="container-fluid" style={{width: "70%", height:'auto', margin: "30px"}}>

              <p>The <b>International Research Olympiad</b> is the premier Olympiad for student researchers.</p>


            </div>

            <div class="container" style={{ height:'auto', margin: "30px"}}>

            <div className="row">

            <div className="col">

              <p> <a href="/about" style={{color: 'black', textDecoration: 'none'}}><b>About the IRO </b></a> </p>
              <p className="smallerlink"> <a href="/about" style={{color: 'black', textDecoration: 'none'}}>What is the IRO? </a> </p>
              <p className="smallerlink"> <a href="/about#updates" style={{color: 'black', textDecoration: 'none'}}>Recent Updates & Contact Info </a> </p>
              {/* <p className="smallerlink"> <a href="/About" style={{color: 'black', textDecoration: 'none'}}>IRO Calendar </a> </p> */}
              <p className="smallerlink"> <a href="/about#board" style={{color: 'black', textDecoration: 'none'}}>Partners and Boards of Advisors </a> </p>

              </div>

              <div className="col">

              <p><b> IRO Exam Information </b></p>
              <p className="smallerlink"> <a href="/about-iro" style={{color: 'black', textDecoration: 'none'}}>About the Exam</a> </p>
              <p className="smallerlink"> <a href="/rules-and-regulations" style={{color: 'black', textDecoration: 'none'}}>Rules and Regulations</a> </p>
              <p className="smallerlink"> <a href="/registration-and-eligibility" style={{color: 'black', textDecoration: 'none'}}>Registration and Eligibility</a> </p>

              </div>
              <div className="col">

              <p><b> Student Center </b></p>
              <p className="smallerlink"> <a href="/start-iro-club" style={{color: 'black', textDecoration: 'none'}}>How to Start an IRO Club </a> </p>
              <p className="smallerlink"> <a href="/practice-exams" style={{color: 'black', textDecoration: 'none'}}>Released and Practice Exams </a> </p>
              <p className="smallerlink"> <a href="/curriculum" style={{color: 'black', textDecoration: 'none'}}>Curriculum </a> </p>

              </div>
              <div className="col">

              <p><b> Media </b></p>
              <p className="smallerlink"> <a href="/press-releases" style={{color: 'black', textDecoration: 'none'}}>Press Releases </a> </p>
              <p className="smallerlink"> <a href="/blog" style={{color: 'black', textDecoration: 'none'}}>IRO Blog </a> </p>
              <p className="smallerlink"> <a href="/iro-in-the-news" style={{color: 'black', textDecoration: 'none'}}>IRO in the News </a> </p>
              <p className="smallerlink"> <a href="/media-kit" style={{color: 'black', textDecoration: 'none'}}>Media Kit + Branding </a> </p>
              <p className="smallerlink"> <a href="/media-contact" style={{color: 'black', textDecoration: 'none'}}>Contact</a></p>

              </div>
              
              <div className="col haram-logo-social-media">

        
                <p className="social-media-icon-css"><a target="_blank" href="https://twitter.com/IntResearchOly" className="hover-change-color-icon-footer"><XIcon/></a></p>
                <p className="social-media-icon-css"><a target="_blank" href="https://www.instagram.com/internationalresearcholympiad/" className="hover-change-color-icon-footer"><InstagramIcon /></a></p>  
                <p className="social-media-icon-css"><a target="_blank" href="https://www.linkedin.com/company/international-research-olympiad/" className="hover-change-color-icon-footer"><LinkedInIcon/></a></p>        
                {/* <p className="social-media-icon-css"><a target="_blank" href="/" className="hover-change-color-icon-footer"><FacebookIcon/></a></p> */}
                <p className="social-media-icon-css">

                  <a style={{marginLeft: "2px"}} href="https://discord.gg/XSHssgwWzB" target="blank" className="hover-change-color-icon-footer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-discord discord-link-svg" viewBox="0 0 16 16">
                      <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                    </svg>
                    </a>

                </p>
             
                      
              </div>

              


            </div>

        

              
            </div>

           
            <Divider variant="middle" component="div" />
            
            <div class="container-fluid" >         
              
              <p className="foottitle" style={{paddingTop:'30px'}}>© 2025 International Research Olympiad. All rights reserved.</p>
              <div class="d-flex" style={{paddingTop:'30px'}}>
                <p className="foottitle footertos"> <EmailOutlinedIcon/> iro@samyakscience.com</p>
                <p className="foottitle"> <FmdGoodOutlinedIcon/> Cambridge, MA</p>   
                <p className="foottitle "> <a style={{color: 'black', textDecoration:'none'}} onClick={handleClick}><FavoriteBorderIcon style={{fontSize: '21px'}}/> Donate </a></p>    
                <p className="foottitle "> <a style={{color: 'black', textDecoration:'none'}} href="/terms-of-service"><AssignmentOutlinedIcon style={{fontSize: '21px'}}/> Terms of Service </a></p>   
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Footer;
