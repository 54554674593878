
import '../App.css';
import esther from "../headshots/esther.webp"
import Stack from '@mui/material/Stack';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';


import Backdrop from '@mui/material/Backdrop';

import Avatar from '@mui/material/Avatar';

import dhruv from "../headshots/dhruv.png";
import yash from "../headshots/yash.png";

import harvard from "../unilogos/hms.svg.png";
import neastern from "../unilogos/neastern.png";
import caltech from "../unilogos/caltech.png";
import waterloo from "../unilogos/waterloo.svg.png"
import johnhop from "../unilogos/johnhop.png"
import utoronto from "../unilogos/utoronto.svg.png"
import mich from "../unilogos/mich.png"
import unclogo from "../unilogos/unc.png"
import rudgerlogo from "../unilogos/rudger.png"
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import rishabheadshot from "../headshots/rishabjainheadshot.png"
import ozeksuheadshot from "../headshots/ozeksuheadshot.jpg"
import sahilheadshot from "../headshots/sahilheadshot.jpeg"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import natashaheadshot from "../headshots/natashaheadshot.png"

import NataliaLopez from "../headshots/NataliaLopez.webp";
import Eashan from "../headshots/eashan.jpg";


// import Alert from '@mui/material/Alert';

import { useState, useEffect, forwardRef, Fragment } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import smallp from "../logos/plogo.png"
import ab1 from "../prodheadshot/ad1.jpeg"
import ab2 from "../prodheadshot/ad2.png"
import ab3 from "../prodheadshot/ad3.png"
import ab4 from "../prodheadshot/ad4.png"
import ab5 from "../prodheadshot/ad5.png"
import ab6 from "../prodheadshot/ad6.png"
import ab7 from "../prodheadshot/ad7.png"
import ab8 from "../prodheadshot/ad8.jpeg"
import ab9 from "../prodheadshot/ad9.png"
import ab10 from "../prodheadshot/ad10.jpeg"
import ab11 from "../prodheadshot/ab11.jpg"
import ab12 from "../prodheadshot/ab12.png"
import ab13 from "../prodheadshot/ab13.jpg"
import ab14 from "../prodheadshot/ab14.jpg"

function BoardofAdvisors() {



    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open, setOpen] = useState(false);
    const [open9, setOpen9] = useState(false);

    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);
    const [realopen1, setRealopen1] = useState(false);
    const [realopen2, setRealopen2] = useState(false);
    const [realopen3, setRealopen3] = useState(false);
    const [realopen4, setRealopen4] = useState(false);
    const [realopen5, setRealopen5] = useState(false);
    const [realopen6, setRealopen6] = useState(false);

    const [realopen7, setRealopen7] = useState(false);
    const [realopen8, setRealopen8] = useState(false);
    const [realopen9, setRealopen9] = useState(false);
    const [realopen10, setRealopen10] = useState(false);

    
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseIn = () => {
    setOpen(false);
  };
  const handleOpenIn = () => {
    setOpen(true);
  };


  
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };
  
  const handleClose7 = () => {
    setOpen7(false);
  };
  
  const handleClose8 = () => {
    setOpen8(false);
  };

  const handleClose9 = () => {
    setOpen9(false);
  };

  const handleClose11 = () => {
    setOpen11(false);
  };
  const handleClose12 = () => {
    setOpen12(false);
  };

  const handleClose13 = () => {
    setOpen13(false);
  };

  const handleClose14 = () => {
    setOpen14(false);
  };
  const handleClose15 = () => {
    setOpen15(false);
  };


  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleOpen5 = () => {
    setOpen5(true);
  };

  const handleOpen6 = () => {
    setOpen6(true);
  };
  
  const handleOpen7 = () => {
    setOpen7(true);
  };

  
  const handleOpen8= () => {
    setOpen8(true);
  };

  const handleOpen9= () => {
    setOpen8(true);
  };

  const handleOpen11= () => {
    setOpen11(true);
  };

  const handleOpen12= () => {
    setOpen12(true);
  };

  
  const handleOpen13= () => {
    setOpen13(true);
  };

  const handleOpen14= () => {
    setOpen14(true);
  };

  const handleOpen15= () => {
    setOpen15(true);
  };



  const realhandleOpen1= () => {
    setRealopen1(true);
  };

  const realhandleClose1 = () => {
    setRealopen1(false);
  };

  const realhandleOpen2= () => {
    setRealopen2(true);
  };

  const realhandleClose2 = () => {
    setRealopen2(false);
  };

  const realhandleOpen3= () => {
    setRealopen3(true);
  };

  const realhandleClose3 = () => {
    setRealopen3(false);
  };

  const realhandleOpen4= () => {
    setRealopen4(true);
  };

  const realhandleClose4 = () => {
    setRealopen4(false);
  };

  const realhandleOpen5= () => {
    setRealopen5(true);
  };

  const realhandleClose5 = () => {
    setRealopen5(false);
  };

  const realhandleOpen6= () => {
    setRealopen6(true);
  };

  const realhandleClose6 = () => {
    setRealopen6(false);
  };



  const realhandleOpen7= () => {
    setRealopen7(true);
  };

  const realhandleClose7 = () => {
    setRealopen7(false);
  };




  const realhandleOpen8= () => {
    setRealopen8(true);
  };

  const realhandleClose8 = () => {
    setRealopen8(false);
  };




  const realhandleOpen9= () => {
    setRealopen9(true);
  };

  const realhandleClose9 = () => {
    setRealopen9(false);
  };



  const realhandleOpen10= () => {
    setRealopen10(true);
  };

  const realhandleClose10 = () => {
    setRealopen10(false);
  };




  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width:  34,
    height: 40,
  }));

  const SmallAvatarr = styled(Avatar)(({ theme }) => ({
    width:  40,
    height: 40,
  }));


  return (
    <div>
        <div>


        </div>
        <div>
        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open1}
            onClick={handleClose1}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Sahil Sood" src={sahilheadshot}/>
            <p className="rishab-jain-textt">Sahil Sood</p>
            
            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">
            Sahil Sood is a distinguished molecular biology student at Harvard University, where he delves into the intricate world of molecular sciences. His academic pursuits are complemented by his role as a researcher at the prestigious Harvard/MIT BROAD Institute, reflecting his commitment to advancing scientific knowledge. Recognized for his outstanding contributions, Sahil has been featured in Forbes, a testament to his remarkable achievements in the academic and research domains. His scholarly impact extends to the publication of three research papers, showcasing his dedication to pushing the boundaries of molecular biology. 
            <br className="br"></br><br className="br"></br>

            Beyond his academic and research endeavors, Sahil is the co-founder and president of HYPE, a 501(c)(3) nonprofit organization. Under his leadership, HYPE has emerged as a force for community sustainability, operating in 15 chapters globally. Sahil oversees all projects and skillfully coordinates their execution. HYPE's philanthropic ef{''}forts are commendable, having donated a substantial sum of $100,127 to support 33,480 impoverished individuals across eight countries. The organization focuses on four crucial pillars within communities: healthcare, youth empowerment, poverty alleviation, and education. 
            <br className="br"></br><br className="br"></br>

            Sahil's involvement in the 2025 IRO as an advisor to the Student Board emphasizes the transformative potential of science, technology, and activism.
                                
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open2}
            onClick={handleClose2}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Rishab Jain" src={rishabheadshot}/>
            <p className="rishab-jain-textt">Rishab Jain</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">
                Rishab Kumar Jain is a distinguished neuroscience researcher currently af{''}filiated with Massachusetts General Hospital. Concurrently, he serves as a Neuroethics researcher at Harvard Medical School's Center of Bioethics, contributing significantly to the intersection of neuroscience and ethical considerations. Rishab's research prowess is evident through his notable publications in journals such as Nature Scientific Data, Frontiers in Oncology, and BMC Bioinformatics. 
                <br className="br"></br>
                <br className="br"></br>
                His outstanding achievements have not gone unnoticed, as exemplified by his recognition as the recipient of the prestigious $50K Regeneron Young Scientist Award. Rishab's impactful contributions to the field have also led to his recognition as a featured researcher by the National Cancer Institute of the National Institutes of Health (NIH). Additionally, his influence extends beyond the scientific community, as he has been honored as one of TIME magazine's 25 Most Influential Teens, showcasing his exceptional dedication and leadership in the field of research. 
                <br className="br"></br>
                <br className="br"></br>
                As an advisor to the board of the 2025 International Research Olympiad, Rishab Kumar Jain brings a wealth of expertise, a stellar track record, and a commitment to advancing knowledge in neuroscience and bioethics. His presence on the advisory board enhances the prestige of the event and reinforces its commitment to promoting excellence in research on a global scale. <br/>
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open3}
            onClick={handleClose3}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Jacob Friedman" src="https://imgur.com/h80FmW2.jpeg" />
            <p className="rishab-jain-textt">Jacob Friedman</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

                Jacob Friedman, a distinguished researcher, member of the Harvard Class of 2028, and passionate climate activist, has made remarkable contributions to environmental science, biology, and climate justice. As an alumnus of the prestigious International Science and Engineering Fair (ISEF), Jacob's early achievements set the stage for his ongoing commitment to scientific exploration.
                <br className="br"></br>
                <br className="br"></br>

                In terms of research, Jacob has sought to understand the biotoxicity and remediation of microplastics through science fair, in addition to biochemically inhibiting fungal infection in cereal crops at the Summer Science Program. His work is helping shape the field of sustainability, combining scientific and social information to inform decisions on complex environmental issues. Jacob is also a competitor in the NCF-Envirothon natural resource competition, co-leading his 2023 team to win 4th Place internationally in New Brunswick, Canada.

                <br className="br"></br>
                <br className="br"></br>

                As a Director of Fridays for Future US, Jacob plays a pivotal role in mobilizing youth in climate justice. His leadership in this international, intersectional cause demonstrates his commitment to creating a sustainable future while inspiring others to join this critical movement. Jacob was also a significant proponent of the LCOY 2023 National Youth Statement on Climate that was sent to COY18, COP28, and State Dept. officials. He is able to bridge environmental policy with the sciences through his efforts in activism.

                <br className="br"></br>
                <br className="br"></br>

                Jacob's multifaceted involvement in grassroots activism and the environmental & biological sciences showcases his dedication to making a lasting impact. His position as an advisor on the IRO's Student Board reflects a deep commitment to environmental justice and a sustainable future in our efforts.



    

            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open4}
            onClick={handleClose4}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Shriya Bhat" src="https://imgur.com/hOveisg.jpeg" />
            <p className="rishab-jain-textt">Shriya Bhat</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">
            Shriya Bhat stands as a distinguished scientist, researcher, and advocate for accessible medicine, making significant contributions to the field of microbiology and STEM education. As a two-time first-place winner at the International Science & Engineering Fair and an alum of the prestigious Research Science Institute (RSI), Shriya has demonstrated excellence in her research endeavors.<br className="br"></br><br className="br"></br>

Currently serving as a researcher at the Wyss Institute under the mentorship of George Church and at the Bhattacharya Lab in the BROAD Institute, Shriya is immersed in cutting-edge scientific exploration. Her commitment to combating antibiotic resistance and innovating af{''}fordable medications has taken her from the workbench at a local clinic lab to the esteemed halls of Harvard Medical School.<br className="br"></br><br className="br"></br>

Beyond her research accomplishments, Shriya is a passionate advocate for STEM education in underserved communities. She is the founder of the 501(c)3 non-profit SySTEM for Success, where she leads projects aimed at fostering STEM education, particularly for hard-of-hearing children and those living in rural India. Shriya's dedication to making science accessible is evident through her impactful initiatives that bridge educational gaps and empower young minds.<br className="br"></br><br className="br"></br>

As an advisor to the board of the 2025 International Research Olympiad, Shriya Bhat brings a wealth of experience, expertise, and a passion for advancing research and education globally. Her commitment to af{''}fordable medicine, STEM accessibility, and advocacy for those with speaking disorders reflects the Olympiad's dedication to fostering inclusive and impactful research initiatives.<br className="br"></br><br className="br"></br>

            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open5}
            onClick={handleClose5}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Okezue Bell" src={ozeksuheadshot}/>
            <p className="rishab-jain-textt">Okezue Bell</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

                Okezue Bell, a Nigerian-American mathematics student at Stanford University, stands at the intersection of academia, artificial intelligence activism, and humanitarian ef{''}forts. His journey as a researcher and inventor has left a mark, transforming lives through applied AI research and groundbreaking initiatives.
                <br className="br"></br>
                <br className="br"></br>
                As the founder and president of Fidutam, one of the largest responsible technology civil society groups, Okezue has harnessed the power of collective action. With nearly 1,600 members across 50+ countries and chapters on college campuses worldwide, Fidutam focuses on responsible technology and has developed a digital ID platform benefiting the bottom billion.
                <br className="br"></br>
                <br className="br"></br>
                Okezue's impact extends beyond Fidutam, as the organization collaborates closely with stakeholders at the White House, Google, the Future of Life Institute, Chatham House, Brookings, OpenAI, and more. Through individual and group workshops, Fidutam has reached over 81,000 students globally, with partnerships spanning organizations like the NAACP, Fab Foundation, Stanford University Human-Centered Artificial Intelligence, Google Code Next Programs, and more. His work and achievements have garnered widespread recognition, with features and publications in renowned outlets such as Bloomberg, Marketplace, ABC, WFMZ, Yahoo News, Yahoo Finance, Business & Market Insider, New Scientist, the Hill, Tech Policy Press, NPR, and more. Okezue has presented at prestigious events like Web Summit, COP27, and Congress' AI Insight Forums, earning the distinction of being the youngest speaker ever.
                <br className="br"></br>
                <br className="br"></br>
                As a distinguished alum of the Research Science Institute and a 2nd Place winner in Biomedical Engineering at the 2022 International Science & Engineering Fair, Okezue Bell embodies the spirit of innovation, activism, and positive change on a global scale.

            </p>
        
            </div>
        </div>
        </Backdrop>

 


        {/* new guys */}


        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open7}
            onClick={handleClose7}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Ram Goel" src="https://www.oregonlive.com/resizer/fD3-r7rJ1Y0uZJqwFhLvyEp_K24=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/3XBUEQR3XFB2DKYBGJVYTQRNAQ.jpg" />
            <p className="rishab-jain-textt">Ram Goel</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

            Ram Krishna Goel, a distinguished researcher and mathematician, joins the advisory board of the 2025 International Research Olympiad, bringing a wealth of accomplishments and expertise. He is currently conducting research in MIT CSAIL on machine learning. 
            <br className="br"></br>
                <br className="br"></br>

Ram's achievements include original algebraic combinatorics research published in the Journal of Combinatorics. Notably, he earned a Bronze Medal at the International Mathematical Olympiad (IMO) in Oslo, Norway, and secured 3rd place Grand Award at ISEF 2022. His accolades in the USA Math Olympiad (USAMO) as a Gold Medalist and perfect scores in the USA Mathematical Talent Search (USAMTS) underscore his exceptional mathematical prowess. Beyond mathematics, Ram has excelled in physics, achieving three-time semifinalist status in the USA Physics Olympiad. His diverse achievements, from national competitions like MATHCOUNTS to being a Regeneron Science Talent Search Scholar, demonstrate his well-rounded capabilities and commitment to scientific exploration.

<br className="br"></br>
                <br className="br"></br>
As an advisor to the International Research Olympiad, Ram's expertise and accomplishments will inspire participants and contribute to the celebration of outstanding research on a global scale.

            </p>
        
            </div>
        </div>
        </Backdrop>


        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open8}
            onClick={handleClose8}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Gopal Tadinanda" src="https://i.imgur.com/DwAaHel.jpeg" />
            <p className="rishab-jain-textt">Gopal Tadinanda</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

                Gopal Tadinada, an accomplished researcher and advocate for STEM education, serves as an esteemed advisor to the 2025 International Research Olympiad. With a strong background in medical research and a commitment to fostering STEM programs, Gopal brings a wealth of experience and innovation to the advisory board.
                <br className="br"></br>
                <br className="br"></br>
                Gopal's research journey includes significant contributions to the Tokuda Lab group in the Surgical Planning Laboratory at Harvard Medical School in Brigham and Women's Hospital. His dedication to advancing medical technology is evident in his work at the University of Louisville Totol Research Lab, where he tested and designed a low-cost automated sample preparation device integrated into a qPCR device. This innovation eliminates the need for manual sample preparation, streamlining the screening process to require just a singular blood sample. His modules have been rigorously tested, forming the foundation of his ongoing research project.
                <br className="br"></br>
                <br className="br"></br>
                Beyond his research endeavors, Gopal is the co-founder of a 501(c)(3) certified non-profit organization dedicated to providing more STEM programs to schools and actively engaging with students in the Louisville Area. His commitment to nurturing the next generation of scientists and researchers aligns with the Olympiad's mission to promote excellence in research and education. Gopal's outstanding achievements were recognized with the H. Robert Horvitz Award for Fundamental Research, one of the top 8 honors at Regeneron ISEF 2023. Additionally, he secured First Place in Biomedical Engineering at the same prestigious event, showcasing the depth of his contributions to the field.
                <br className="br"></br>
                <br className="br"></br>
                As an advisor to the board, Gopal Tadinada's expertise, research acumen, and passion for STEM education contribute to the Olympiad's commitment to fostering a global community of research excellence. His dedication to advancing medical technology and empowering future scientists serves as an inspiration to participants and adds invaluable insights to the advisory board.
                                
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open9}
            onClick={handleClose9}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" src={ab1} />
            <p className="rishab-jain-textt">Gopal Tadinada</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

                Gopal Tadinada, an accomplished researcher and advocate for STEM education, serves as an esteemed advisor to the 2025 International Research Olympiad. With a strong background in medical research and a commitment to fostering STEM programs, Gopal brings a wealth of experience and innovation to the advisory board.
                <br className="br"></br>
                <br className="br"></br>
                Gopal's research journey includes significant contributions to the Tokuda Lab group in the Surgical Planning Laboratory at Harvard Medical School in Brigham and Women's Hospital. His dedication to advancing medical technology is evident in his work at the University of Louisville Totol Research Lab, where he tested and designed a low-cost automated sample preparation device integrated into a qPCR device. This innovation eliminates the need for manual sample preparation, streamlining the screening process to require just a singular blood sample. His modules have been rigorously tested, forming the foundation of his ongoing research project.
                <br className="br"></br>
                <br className="br"></br>
                Beyond his research endeavors, Gopal is the co-founder of a 501(c)(3) certified non-profit organization dedicated to providing more STEM programs to schools and actively engaging with students in the Louisville Area. His commitment to nurturing the next generation of scientists and researchers aligns with the Olympiad's mission to promote excellence in research and education. Gopal's outstanding achievements were recognized with the H. Robert Horvitz Award for Fundamental Research, one of the top 8 honors at Regeneron ISEF 2023. Additionally, he secured First Place in Biomedical Engineering at the same prestigious event, showcasing the depth of his contributions to the field.
                <br className="br"></br>
                <br className="br"></br>
                As an advisor to the board, Gopal Tadinada's expertise, research acumen, and passion for STEM education contribute to the Olympiad's commitment to fostering a global community of research excellence. His dedication to advancing medical technology and empowering future scientists serves as an inspiration to participants and adds invaluable insights to the advisory board.
                                
            </p>
        
            </div>
        </div>
        </Backdrop>


        {/* FOR THE REAL BOARD fgdsfgsdfgsdfgsdfgsdfgfgfdg  */}


        <Backdrop
            sx={{ color: 'white', top: "0", zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen1}
            onClick={realhandleClose1}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="Desmond Lun" src={ab5} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/desmond-lun-91b5624/" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Desmond Lun</p>
                <p className="education-css-textt"><i>Professor of Computer Science</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
            <br className="br"/>
            <br className="br"/>

                Desmond Lun is a Professor in the Department of Computer Science at Rutgers University–Camden and the Department of Plant Biology at Rutgers University–New Brunswick. Prior to his current positions at Rutgers, which he assumed in 2010, Lun served as an Associate Professor at the University of South Australia, where he was also the Director of the Phenomics and Bioinformatics Research Centre. From 2006 to 2008, he engaged in computational biology research at the Broad Institute of MIT and Harvard, in addition to holding a Research Fellow position in Genetics at Harvard Medical School.
                <br className="br"/>
                <br className="br"/>
                Dr. Lun's research interests are centered on biological signal processing and systems biology, fields in which he has made substantial contributions. His achievements in research have been acknowledged by the 2023 Edison Patent Award in Forensic Science, underscoring his impact and innovation in the domain of computational biology. Dr. Lun also co-authored a book "Network Coding: An Introduction" with Tracey Ho, published by Cambridge University Press in 2008, showcasing his expertise in network coding theory.
                ​​​<br className="br"/>
                ​<br className="br"/>
                As an advisor for the IRO, Dr. Lun's experiences exemplify his profound understanding of interdisciplinary research. His dual expertise in computer science and biology aligns with the IRO's goal of cultivating analytical and critical thinking skills as growing student researchers. Dr. Lun's commitment to advancing scientific knowledge, coupled with his experience in diverse research environments, positions him to guide the IRO effectively.
                <br className="br"/>
                ​<br className="br"/>

                                
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen2}
            onClick={realhandleClose2}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="Aditya Jain" src={ab6} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/adityajain2000/" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Aditya Jain</p>
                <p className="education-css-textt"><i>M.D. candidate at Harvard Medical School</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">

                <br className="br"/>
                <br className="br"/>
                Mr. Aditya Jain is an M.D. candidate at Harvard Medical School, interested in artificial intelligence (AI), medical imaging, computational synthetic biology, and medical devices. Mr. Jain’s work highlights a keen focus on AI's revolutionary impact across multiple medical disciplines including radiology, neurology, cardiology, surgery, and oncology. 


                <br className="br"/>
                <br className="br"/>
                His academic and professional pursuits are marked by active engagement in cutting-edge research at both Massachusetts General Hospital (MGH) and the Broad Institute of MIT and Harvard. At the Broad Institute, he works at the Eric & Wendy Schmidt Center, contributing to projects in predicting heart attacks with coronary angiography in partnership with IBM. Furthermore, Jain presides over the Samyak Science Society, a 501(c)(3) organization, spearheading efforts to motivate young individuals towards STEAM disciplines.
                ​​​<br className="br"/>
                ​<br className="br"/>
                Mr. Jain's recent scholarly contributions include the creation of "ICOR," a recurrent neural network-based tool designed for codon optimization in Escherichia coli, and advancements in immuno-peptidomics through sophisticated quantification methods. He is also an avid writer, and currently writes op-eds on healthcare and medical education as a Doximity Fellow.

                ​<br className="br"/>
                ​<br className="br"/>
                Aditya Jain’s extensive engagement in scientific research and science communication position him as a pivotal figure for the International Research Olympiad, embodying the ideals of leadership and mentorship necessary to nurture future generations of scientific innovators. Mr. Jain is also an International Science and Engineering Fair as well as Broadcom MASTERS alum, helping us inspire and guide the next generations of researchers.
                <br className="br"/>
                ​<br className="br"/>


                                
            </p>
        
            </div>
        </div>
        </Backdrop>


    <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen3}
            onClick={realhandleClose3}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="Erin Marie Baker" src={ab7} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/erin-baker-034a207/" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Erin Marie Baker</p>
                <p className="education-css-textt"><i>Associate Professor at the University of North Carolina at Chapel Hill</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
            <br className="br"/>
            <br className="br"/>
            Dr. Erin S. Baker is a bioanalytical chemist and an Associate Professor at the University of North Carolina at Chapel Hill. Her research group has made significant contributions to the fields of proteomics, metabolomics, glycomics, lipidomics, and exposomics over the last decade to evaluate how chemical exposure affects human health.
            
                <br className="br"/>
                <br className="br"/>

Dr. Baker’s current research endeavors include developing and optimizing high-throughput ion mobility spectrometry (IMS) separations and coupling these with mass spectrometry, among using many other techniques and platforms. Dr. Baker’s group at University of North Carolina hosts outreach activities, communicating their science to the general public. In particular, Dr. Baker is excited to share research with K-12 schools and students as well—something that aligns with the International Research Olympiad’s mission.

                
                ​​​<br className="br"/>
                ​<br className="br"/>
                Dr. Baker's profound experience and commitment to not only advancing science but communicating it are in perfect alignment with the International Research Olympiad. As an advisor to the IRO, Dr. Baker brings a wealth of knowledge in sophisticated analytical techniques, offering substantial contributions to our organization's objectives, the preparation of our examinations, and serving as an inspiration for students.

                ​<br className="br"/>
                ​<br className="br"/>



                                
            </p>
        
            </div>
        </div>
        </Backdrop>


        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen4}
            onClick={realhandleClose4}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab10} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/s-george-djorgovski-59a3622/" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">George Djorgovski</p>
                <p className="education-css-textt"><i>Professor of Astronomy and Data Science</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
            <br className="br"/>
            <br className="br"/>
            S. George Djorgovski serves as Professor of Astronomy and Data Science at the California Institute of Technology. He also leads as the Director of the Center for Data-Driven Discovery and offers his expertise as the Senior Data Science Advisor for the W. M. Keck Foundation. Professor Djorgovski also holds the title of President Emeritus at the International Astroinformatics Association.
            
                <br className="br"/>
                <br className="br"/>

                Professor Djorgovski has been recognized as a Fellow of the American Association for the Advancement of Science and the Institute for the Advancement of Engineering, as well as a Harvard Junior Fellow. His diverse professional interests encompass computational and data science, astroinformatics, and virtual observatories.

                
                ​​​<br className="br"/>
                ​<br className="br"/>
                Professor Djorgovski’s significant contributions to his field align well with the IRO’s values of integrity and excellence within research. His leadership qualities and expertise in the field of astronomy will make a valuable addition to the board.

                ​<br className="br"/>
                ​<br className="br"/>



                                
            </p>
        
            </div>
        </div>
        </Backdrop>


        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen5}
            onClick={realhandleClose5}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab1} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/millena-xavier-martins-conecte-se/?originalSubdomain=br" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Millena Martins</p>
                <p className="education-css-textt"><i>Entrepreneur</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
            <br className="br"/>
            <br className="br"/>
            Millena Xavier Martins, a entrepreneur and researcher, has made significant strides in utilizing artificial intelligence to address educational challenges and health issues. Recognized as a Forbes Under 30 honoree, Millena is at the forefront of leveraging AI to impact Science Olympiads and contribute to autism diagnosis.                
                <br className="br"/>
                <br className="br"/>

                As the founder and CEO of "Prep Olimpíadas," Brazil's largest NGO dedicated to preparing students for Science Olympiads, Millena demonstrates a strong commitment to accessibility, particularly for underprivileged communities. Through collaboration with over 200 Brazilian schools, Prep Olimpíadas has provided AI resources that benefit more than 60,000 students, fostering a culture of learning and innovation. Millena's dedication extends to addressing racial issues through her involvement in OBAFRO, the pioneering Brazilian Olympiad focusing on these challenges. This initiative has garnered media recognition and attracted over 20,000 participants from across Brazil, showcasing Millena's impact on a broader societal level.

                
                ​​​<br className="br"/>
                ​<br className="br"/>
                With an impressive track record of over 37 science awards, including multiple gold medals in Brazilian Tech Olympiads (OBT), Brazilian Informatics Olympiad (OBI), and National Science Olympiad (ONC), Millena's contributions to the scientific community have been widely acknowledged. Her excellence has been recognized by being a finalist for a prestigious prize from the Brazilian Society of Progress and Science.

                ​<br className="br"/>
                ​<br className="br"/>



                                
                </p>
        
            </div>
            </div>
        </Backdrop>

        
        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen6}
            onClick={realhandleClose6}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab8} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://cos.northeastern.edu/people/gregory-fiete/" target="_blank"><i><LanguageIcon style={{paddingBottom: "5px"}}/> Website</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Gregory Fiete</p>
                <p className="education-css-textt"><i>Theoretical physicist and professor of physics</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
                <br className="br"/>
                <br className="br"/>
                Professor Gregory Fiete, a theoretical physicist and professor of physics at Northeastern University, holds a bachelor's degree in physics from Purdue University, and a PhD in physics from Harvard University.  Upon receiving his PhD, Professor Fiete conducted postdoctoral work at the Kavli Institute for Theoretical Physics (KITP) at UC Santa Barbara, and also at Caltech where he was a Lee A. DuBridge Fellow.  His research interests span a broad range of topics concerning quantum phenomena in solids, including topological states of matter.  Professor Fiete’s work contributes to the fundamental understanding of quantum materials and lays the groundwork for potential applications in quantum computing and quantum information processing.  He leads the Center for Quantum Matter and Correlated Electron Theory at Northeastern University and is a Core Member of the Quantum Materials and Sensing Institute (QMSI) at Northeastern University.
                <br className="br"/>
                <br className="br"/>
                As a result of Professor Fiete's contributions to the field of theoretical physics, he has received prestigious honors including the NSF CAREER Award, the DARPA Young Faculty Award,  the Presidential Early Career Award for Scientists and Engineers (PECASE), and a Bessel Research Award from the Humboldt Foundation.  He has been named a DARPA Director's Fellow and a Simons Fellow in Theoretical Physics.   He is an elected Fellow of the American Physical Society (APS).  
                <br className="br"/>
                <br className="br"/>
                Professor Fiete’s work in theoretical physics and his accolades coincide with the IRO’s goal of engaging students in science and STEM research.  Professor Fiete has expressed excitement to inspire the next generation of students and researchers through STEM education. His experience in the field of quantum materials makes him a perfect fit on the IRO’s Board of Advisors, and he adds significant value to the olympiad as a whole.
                <br className="br"/>
                <br className="br"/>           
            </p>
        
            </div>
        </div>
        </Backdrop>



        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen7}
            onClick={realhandleClose7}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab11} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/ashley-bruce-89bb82246/?originalSubdomain=ca" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Ashley Bruce</p>
                <p className="education-css-textt"><i>Professor of Cell & Systems Biology</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
                <br className="br"/>
                <br className="br"/>
                Professor Ashley Bruce, currently a professor at the University of Toronto, is a distinguished scientist in the field of developmental biology. Her research focuses on cell and animal biology, with the eventual goal of understanding the connection between morphogenesis and embryonic tissue patterning. Professor Bruce’s lab uses the zebrafish embryo as an experimental model to investigate fundamental cellular properties and mechanisms.
                <br className="br"/>
                <br className="br"/>
                Professor Bruce earned her Ph.D. from Harvard University and held postdoctoral research positions at Princeton University and the University of Chicago.                <br className="br"/>
                <br className="br"/>
                Professor Bruce's expertise in and significant contributions to cell and animal developmental biology make her a valuable addition to the board. Her involvement with the IRO introduces a depth of understanding and insight into research, especially for our examinations which include topics in biology.
                <br className="br"/>
                <br className="br"/>           
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen8}
            onClick={realhandleClose8}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab2} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/otman-basir-ba1258178/?originalSubdomain=ca" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Otman Basir</p>
                <p className="education-css-textt"><i>Professor in Electrical and Computer Engineering</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
                <br className="br"/>
                <br className="br"/>
                Otman Basir, a Professor in the Electrical and Computer Engineering department at the University of Waterloo, also serves as the Director of the Urban Informatics Corporation, the Associate Director of the Waterloo Institute for Health Informatics Research, and the Associate Director for the Pattern Recognition and Machine Intelligence Laboratory. Additionally, he is the founder, president, and CEO of Intelligent Mechatronic Systems, a leader in telematics and infotainment technologies, and co-founder of Voice-Enabled Systems and Technology.                 <br className="br"/>
                <br className="br"/>
                With research interests spanning Intelligent Embedded Systems, Sensory Systems Design, Biologically Inspired Intelligent Systems, and Human-Computer Interface Systems, Professor Basir has an impressive record of over 400 publications and holds 121 issued and pending patents. Professor Basir's contributions have been recognized with prestigious awards including the Ontario Premier Research Excellence Award, the Canada Foundation Innovation Award, and multiple Professor of the Year Awards. 
                <br className="br"/>
                Professor Basir’s wide-ranging expertise will be a valuable asset to the IRO as the team looks toward covering new technologies within our examinations. 
                <br className="br"/>
                <br className="br"/>           
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen9}
            onClick={realhandleClose9}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab9} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://www.linkedin.com/in/ecandersen/" target="_blank"><i><LinkedInIcon style={{paddingBottom: "5px"}}/> LinkedIn</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Erik Andersen</p>
                <p className="education-css-textt"><i>Geneticist</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
                <br className="br"/>
                <br className="br"/>
                Dr. Erik Andersen is a geneticist at Johns Hopkins University. His research spans molecular, quantitative, and population genetics and genomics, with significant contributions in chromatin remodeling in Caenorhabditis elegans, a shift to quantitative genetics and genomics during his postdoctoral studies at Princeton University. Dr. Andersen's work, especially at Northwestern University from 2013 to 2023, focused on the genetics underlying phenotypic differences in nematodes, producing substantial advancements in systems biology, ecology, and genetics through comprehensive studies and wild strain collections.
                <br className="br"/>
                In 2023, Dr. Andersen moved his laboratory to Johns Hopkins University, continuing his pioneering work on the genetics and genomics of Caenorhabditis and other nematode species. His career is decorated with numerous honors and grants, such as the Pew Biomedical Scholar Award and the National Science Foundation CAREER Award, reflecting his impact on the fields of genetics, genomics, and beyond. Dr. Andersen's expertise and ongoing research continually contribute valuable insights and innovations in the fields of evolutionary genetics and genomics.
                <br className="br"/>
                Overall, his expertise and contributions in the field make him a valuable asset to research and innovation in genetics and genomics. With his experience and knowledge, Dr. Andersen will help foster the IRO’s mission of providing the necessary critical thinking skills for students to excel in the scientific research community.
                <br className="br"/>
                <br className="br"/>           
            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={realopen10}
            onClick={realhandleClose10}
        >

<div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', top: "10%", position: 'absolute', height:'100vh', width:'100%'}}>
            <div className="col">


            <div className="box-width-for-side-corner-thing-dshfsalhdfadsf">
                <Avatar sx={{borderRadius: '1px'}} className="avater-size-css-two" alt="S. George Djorgovski" src={ab4} />
                <p className="education-css-text"><a style={{textDecoration: "none", color: "white"}} href="https://tara-finegan.squarespace.com" target="_blank"><i><LanguageIcon style={{paddingBottom: "5px"}}/> Website</i></a></p>
                {/* <Avatar className="avater-size-css" alt="Gregory Fiete" src={neastern} /> */}
                {/* <p className="under-education-css-text"> • dsasdgfasdf</p> */}
            </div>
            <div>
                <p className="rishab-jain-textt-two">Tara Finegan</p>
                <p className="education-css-textt"><i>Assistant Research Professor in the Division of Biological Sciences</i></p>
                <br className="br"/>
            </div> 
            <br/>
            <p className="rishab-jain-text-tagg-two bigblueonthewingg">
                <br className="br"/>
                <br className="br"/>
                Dr. Tara Finegan is an Assistant Research Professor in the Division of Biological Sciences at the University of Missouri. Dr. Finegan’s lab explores how animal tissues acquire and maintain their shape,  studying the behavior of cells in the context of epithelial tissues.
                <br className="br"/>
                Particularly, her lab focuses on discerning how cells orient their divisions such that daughter cells are born in the correct position and how misplaced cells incorporate back into their parental tissue. These questions have direct biomedical implications in cancer and developmental disorders. 
                <br className="br"/>
                Dr. Finegan studied Natural Sciences as an undergraduate at the University of Cambridge in the UK, specializing in Genetics. She continued with graduate studies at the University of Cambridge, obtaining a Master’s degree with Prof. Rafael Carazo Salas in the field of yeast biochemistry and a PhD in Developmental Mechanics with Prof. Bénédicte Sanson. She conducted postdoctoral research in the Department of Biology at the University of Rochester, USA and furthered her theoretical skills under the guidance of Professors J.M. Schwarz, Lisa Manning and Jennifer Ross in the Physics Department at the University of Syracuse, USA.
                <br className="br"/>
                Dr. Finegan’s expertise and wide experience in biology research will make her an immensely helpful addition to the IRO Board of Advisors.
                <br className="br"/>   
                <br className="br"/>          
            </p>
        
            </div>
        </div>
        </Backdrop>


        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open12}
            onClick={handleClose12}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Okezue Bell" src={yash}/>
            <p className="rishab-jain-textt">Yashvir Sabharwal</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

            Yashvir Sabharwal is a distinguished student and student board of advisor for this year's International Research Olympiad (IRO), bringing a unique perspective to the event. Throughout the course of his career, Yashvir has demonstrated exceptional leadership and dedication to STEM education and research. As a member of the Samyak Science Society for over 3 years, he has been able to connect with students all over the world through workshops, teaching, and donating hundreds of school supplies.
                <br className="br"></br>
                <br className="br"></br>
                Currently, Yash collaborates with the Medical AI Research Center and Alljoined as a research collaborator, contributing to EEG reconstruction and fMRI projects in partnership with Stability AI, Stanford AIMI Center, and Fast.ai. His work has been recognized at prominent conferences such as CVPR & NeurIPS, and he also holds multiple citations. At the pre-collegiate level, Yashvir has been named a Regeneron ISEF Grand Finalist multiple times, taking home over $200,000 in winnings. He has also scored in the Top 50 for the US Biology Olympiad and has been awarded an American Cancer Society Cancer Research Award, amongst numerous other accolades.
                <br className="br"></br>
                <br className="br"></br>
                Outside of research and academics, Yash is an accomplished Cadet Commander with the Civil Air Patrol, excelling in leadership and volunteer service. Over the summer, Yash was awarded a $35,000 scholarship to pursue a private pilot’s license and he currently runs the AFJROTC program at his school. Recently, Yash was named the “AFJROTC Cadet of the Year” out of over 100,000 students and 800+ schools across multiple continents.
                <br className="br"></br>
                <br className="br"></br>
                Yashvir's diverse experiences and commitment to advancing STEM knowledge make him an invaluable member of the IRO Student Board, enhancing the event's prestige and dedication to promoting research excellence globally.

            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open13}
            onClick={handleClose13}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Okezue Bell" src={NataliaLopez}/>
            <p className="rishab-jain-textt">Natalia Lopez</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

              Natalia is a Mechanical Engineering student at Harvard with an extraordinary passion for space exploration. Marked by her research at MIT through the MITES program, she explored the transformative implications of AI in space travel. Natalia also developed an AI model to detect exoplanets for InspiritAI and has been an avid participant in engineering competitions since she was 12.
                <br className="br"></br>
                <br className="br"></br>
                Coming from Aguascalientes, Mexico, Natalia is a first-generation university student, making her accomplishments even more remarkable. Despite the challenges her family faced with limited educational access, Natalia dedicated time to STEMing Youth, where she was part of a team creating free STEM lessons for children in underrepresented communities, encouraging them to pursue STEM fields.
                <br className="br"></br>
                <br className="br"></br>
                At Harvard, Natalia contributes to the Harvard Technology Review, works with the Harvard Rocketry Team, and prepares for an internship with Amazon in 2025 as an Amazon Future Engineer Scholar. She is also developing an innovative project through Greenhouse Scholars, showcasing her relentless drive to push boundaries and inspire others.
                <br className="br"></br>
                <br className="br"></br>
                As a member of the International Research Olympiad board, Natalia continues to dedicate herself to encouraging youth in science and adds perspective for other FGLI students wishing to pursue esteemed careers.

            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open14}
            onClick={handleClose14}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Okezue Bell" src={Eashan}/>
            <p className="rishab-jain-textt">Eashan Iyer</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">

                Eashan Iyer is a distinguished physics student and emerging scientist serving as a board member for the International Research Olympiad. He is a passionate physicist, having co-authored a research paper titled “Quantization of the ModMax Oscillator”, published in Physical Review D, a leading academic journal in high energy physics. He is currently attending Brown University.
                <br className="br"></br>
                <br className="br"></br>
                He previously served as the Vice President of Curriculum at the International Research Olympiad, managing the team responsible for creating practice materials such as mock examinations and study guides. Eashan has work experience at multiple organizations including Students 2 Science, a nonprofit dedicated to K-12 STEM outreach, and Pfizer, where he created educational VR solutions.
                <br className="br"></br>
                <br className="br"></br>
                Beyond his research accomplishments, Eashan is passionate about making history come to life through the use of digital technology. He created a digital walking tour of Mount Tabor, a unique, historic neighborhood in northern New Jersey. Eashan has also worked at Macculloch Hall Historical Museum to enhance the visitor experience and create accessible audio tour.
                <br className="br"></br>
                <br className="br"></br>
                As an advisor to the board, Eashan brings a track record of expertise in research and passion for STEM outreach efforts. He will reinforce the Olympiad’s commitment to fostering a community that excels in research. 

            </p>
        
            </div>
        </div>
        </Backdrop>

        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open15}
            onClick={handleClose15}
        >

        <div className="row rowpad1" data-aos="fade-up" style={{padding:'30px', overflowY: 'scroll', height:'100vh', width :'100%'}}>
            <div className="col">

            <Avatar sx={{width: '150px !important', height: '150px !important', margin: 'auto'}} className="avater-size-css" alt="Okezue Bell" src={esther}/>
            <p className="rishab-jain-textt">Esther Kinyanjui</p>

            {/* PUT DESCRIPTION HERE  */}
            <p className="rishab-jain-text-tagg bigblueonthewingg">
              I’m Esther Kinyanjui, a senior at MIT majoring in Computer Science with a minor in Mathematics. Originally from Nairobi, Kenya, I graduated from The Kenya High School before starting my undergraduate studies. I have always had a passion for research.
              <br className="br"></br>
              <br className="br"></br>
              During high school, participating in the Kenya Science and Engineering Fair deepened my interest in research, particularly through the STEM projects I worked on. At MIT, I joined the Digital Humanities Lab, contributing to the Sonification Toolkit project, the Ukrainian Scientific Diaspora project, and helped lay the foundation for the Self-Sufficient Cities project (https://digitalhumanities.mit.edu/projects). 
              <br className="br"></br>
              <br className="br"></br>
              Currently, I am a student researcher in the Data Visualization group at MIT CSAIL, where I collaborate with Professor Arvind Satyanarayan and graduate student Joshua Pollock on a semi-formal programming in data visualization project (https://vis.csail.mit.edu/). I am excited to work with all of you.
              
            </p>
        
            </div>
        </div>
        </Backdrop>
      </div>

      <div>
        <div class="row">
        <div className="margin-top-Highly-motivated-Unlimited-potentiall" data-aos="fade-up"> 
            <p className="Highly-motivated-Unlimited-potentiall"><span className="Exceptionally-motivated-Limitless-potential">Our Board of Advisors</span></p>
            <p className="px-16" style={{textAlign: "center", paddingTop: "10px"}}>
            The IRO Board of Advisors consists of academics, olympiad experts, and research professionals. The Board provides critical insight to shape the contest and its examinations. These range from insights about what research skills ought to be tested to how judging criteria shall be developed. The IRO is not-for-profit and does not compensate Board of Advisors to any extent
            </p>
            </div>
            <div style={{marginTop: '70px'}} className="hide-div-id-dhafeyuvbejhvczxbvvcmnbxmnzcxvmnxbvzsjdkjhdsfaljhfdsjriujukhrgjhkghbggg">
            
            
            <div data-aos="fade-up">
                <Stack direction="row" spacing={5} className="more-people">

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen1} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatarr className="smaller-avater-professor-css-no-invert" alt="Caltech" src={rudgerlogo} style={{ borderRadius: '0px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Desmond" src={ab5} />
                    </Badge>
                    <p className="rishab-jain-text" >Desmond L.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen2} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatarr className="smaller-avater-professor-css-no-invert" alt="Waterloo" src={harvard} style={{ height: "40px", width: "34px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Aditya" src={ab6} />
                    </Badge>
                    <p className="rishab-jain-text" >Aditya Jain</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <button onClick={realhandleOpen3} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatar className="smaller-avater-professor-css-no-invert" alt="Harvard Med" src={unclogo} style={{ borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Erin" src={ab7} />
                    </Badge>
                    <p className="rishab-jain-text">Erin B.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen4} style={{backgroundColor: 'transparent', border: 'none'}}>
                    
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatar className="smaller-avater-professor-css-no-invert" alt="Harvard Med" src={caltech} style={{ width: "40px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="George" src={ab10} />
                    </Badge>
                    <p className="rishab-jain-text" >George D.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen6} style={{backgroundColor: 'transparent', border: 'none'}}>
                    
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatar className="smaller-avater-professor-css-no-invert" alt="Northeastern" src={neastern} style={{ width: "40px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Gregory" src={ab8} />
                    </Badge>
                    <p className="rishab-jain-text" >Gregory F.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
        

              


    
                </Stack>
            </div>

            <div data-aos="fade-up">
                <Stack direction="row" spacing={5} className="more-people">

              

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen7} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatarr className="smaller-avater-professor-css-no-invert" alt="Waterloo" src={utoronto} style={{ height: "40px", width: "34px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Aditya" src={ab3} />
                    </Badge>
                    <p className="rishab-jain-text" >Ashley Bruce</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <button onClick={realhandleOpen8} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatarr className="smaller-avater-professor-css-no-invert" alt="Harvard Med" src={waterloo} style={{ borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Erin" src={ab2} />
                    </Badge>
                    <p className="rishab-jain-text">Otman Basir</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen9} style={{backgroundColor: 'transparent', border: 'none'}}>
                    
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatar className="smaller-avater-professor-css-no-invert" alt="Harvard Med" src={johnhop} style={{ width: "30px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="George" src={ab14} />
                    </Badge>
                    <p className="rishab-jain-text" >Erik Andersen</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen10} style={{backgroundColor: 'transparent', border: 'none'}}>
                    
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatar className="smaller-avater-professor-css-no-invert" alt="Northeastern" src={mich} style={{ width: "32px", borderRadius: '3px', overflow: 'none'}}/>
                        }
                    >
                        <Avatar className="avater-size-css" alt="Gregory" src={ab13} />
                    </Badge>
                    <p className="rishab-jain-text" >Tara Finegan</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
        

                <div className="jacobfpaddss" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen5} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                        <SmallAvatarr className="smaller-avater-professor-css-no-invert" alt="Northeastern" src={smallp} style={{width: "32px",  height: "35px", borderRadius: '3px', overflow: 'hidden'}}/>
                        }
                    >
                    
                        <Avatar className="avater-size-css" alt="Millena" src={ab1} />
                    </Badge>
                   
                    <p className="rishab-jain-text">Millena M.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>


    
                </Stack>
            </div>

        
            
            </div>

      

            <div style={{marginTop: '70px'}} className="hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgtt">
    
            
            {/* smal screen advisor _______________________________________________++______________________+__________+__________________________ */}
            
            <div data-aos="fade-up">
                <Stack direction="row" spacing={5} className="more-people">

                <div>
                    <button onClick={realhandleOpen1} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Desmond" src={ab5} />
                    <p className="rishab-jain-text">Desmond L.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

            
                
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen2} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Aditya Jain " src={ab6} />
                    <p className="rishab-jain-text" >Aditya Jain</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                

                </Stack>
            </div>

            <div data-aos="fade-up">
                <Stack direction="row" spacing={5} className="more-people">

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen3} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Aditya Jain " src={ab7} />
                    <p className="rishab-jain-text" >Erin B.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen7} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Erin B." src={ab11} />
                    <p className="rishab-jain-text" >Ashley B.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>


                </Stack>
            </div>

            <div data-aos="fade-up">
                <Stack direction="row" spacing={6} className="more-people">
              
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen4} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="George D." src={ab10} />
                    <p className="rishab-jain-text">George D.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen9} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Aditya Jain " src={ab14} />
                    <p className="rishab-jain-text" >Erik A.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
                </Stack>
            </div>
            <div data-aos="fade-up">
                <Stack direction="row" spacing={6} className="more-people">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen10} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Erin B." src={ab4} />
                    <p className="rishab-jain-text" >Tara F.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen5} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="George D." src={ab1} />
                    <p className="rishab-jain-text">Millena M.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
              
                </Stack>
            </div>
            <div data-aos="fade-up">
                <Stack direction="row" spacing={6} className="more-people">
               
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen8} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Aditya Jain " src={ab12} />
                    <p className="rishab-jain-text" >Otman B.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={realhandleOpen6} style={{backgroundColor: 'transparent', border: 'none'}}>
                    <Avatar className="avater-size-css" alt="Gregory" src={ab8} />
                    <p className="rishab-jain-text" >Gregory F.</p>
                    <p className="rishab-jain-text-tag">Learn More</p>
                    </button>
                </div>
                </Stack>
            </div>
            
            
            </div>
            <div className="margin-top-Highly-motivated-Unlimited-potentiall" data-aos="fade-up"> 
            <p className="Highly-motivated-Unlimited-potentiall"><span className="Exceptionally-motivated-Limitless-potential">Our Student Board of Advisors</span></p>
            <p className="px-16" style={{textAlign: "center", paddingTop: "10px"}}>
            The IRO Student Board of Advisors consists of student researchers at the undergraduate and high school level who have participated in science competitions and olympiads previously. The Student Board provides insight to shape the overall IRO experience. These range from insights about creating a fun, collaborative environment, fostering the IRO alumni network and community, and engaging students. The IRO is not-for-profit and does not compensate Student Board of Advisors to any extent.
            </p>
            </div>
            <div style={{marginTop: '70px'}} className="hide-div-id-dhafeyuvbejhvczxbvvcmnbxmnzcxvmnxbvzsjdkjhdsfaljhfdsjriujukhrgjhkghbgg">
                {/* <div data-aos="fade-up">
                    <p className="Founder-text-css">President</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar className="avater-size-css" alt="Rohan Singh" />
                        <p className="rishab-jain-text">----</p>
                        <p className="rishab-jain-text-tag">--- & ---</p>
                    </div> */}
                

                <div data-aos="fade-up">
                    <Stack direction="row" spacing={5} className="more-people">

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen2} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Rishab Jain" src={rishabheadshot} />
                        <p className="rishab-jain-text">Rishab Jain</p>
                        <p className="rishab-jain-text-tag">Chair</p>
                        </button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen11} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Dhruv Ramu" src={dhruv} />
                        <p className="rishab-jain-text">Dhruv Ramu</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen5} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Okezue Bell" src={ozeksuheadshot} />
                        <p className="rishab-jain-text" >Okezue Bell</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen8} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Gopal T." src="https://i.imgur.com/DwAaHel.jpeg" />
                        <p className="rishab-jain-text" >Gopal T.</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen12} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Rishab Jain" src={yash} />
                        <p className="rishab-jain-text">Yashvir S.</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>                  
                    

                    {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen6} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Natasha K..." src={natashaheadshot} />
                        <p className="rishab-jain-text" >Natasha K.</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div> */}

                    </Stack>
                </div>
                <div data-aos="fade-up">
                    <Stack direction="row" spacing={5} className="more-people">


                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen4} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Shriya Bhat" src="https://imgur.com/hOveisg.jpeg" />
                        <p className="rishab-jain-text">Shriya Bhat</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>


                    <div className="jacobfpaddss" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen3} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Jacob Frie..." src="https://imgur.com/h80FmW2.jpeg" />
                        <p className="rishab-jain-text">Jacob F.</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen1} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Sahil Sood" src={sahilheadshot}/>
                        <p className="rishab-jain-text">Sahil Sood</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    </Stack>
                </div>
                <div data-aos="fade-up">
                    <Stack direction="row" spacing={5} className="more-people">
                    
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen7} style={{backgroundColor: 'transparent', border: 'none'}}>
                          <Avatar className="avater-size-css" alt="Ram Goel" src="https://www.oregonlive.com/resizer/fD3-r7rJ1Y0uZJqwFhLvyEp_K24=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/3XBUEQR3XFB2DKYBGJVYTQRNAQ.jpg" />
                          <p className="rishab-jain-text">Ram Goel</p>
                          <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                      </div>

                      
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen13} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Sahil Sood" src={NataliaLopez}/>
                        <p className="rishab-jain-text">Natalia Lopez</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>
        
        
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen14} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Sahil Sood" src={Eashan}/>
                        <p className="rishab-jain-text">Eashan Iyer</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleOpen15} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <Avatar className="avater-size-css" alt="Sahil Sood" src={esther}/>
                        <p className="rishab-jain-text">Esther K.</p>
                        <p className="rishab-jain-text-tag">Learn More</p>
                        </button>
                    </div>
        

                    </Stack>
                </div>
                
              

             
            </div>

          
        </div>

        <div class="row">
                <div>
                  

                  <div style={{marginTop: '70px'}} className="hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgt">
            
                  
                    {/* smal screen advisor _______________________________________________++______________________+__________+__________________________ */}
                    
                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={5} className="more-people">

                        <div>
                          <button onClick={handleOpen2} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Rishab Jain" src={rishabheadshot} />
                            <p className="rishab-jain-text">Rishab J.</p>
                            <p className="rishab-jain-text-tag">Chair</p>
                          </button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen5} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Okezue Bell" src={ozeksuheadshot} />
                            <p className="rishab-jain-text" >Okezue B</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                      </Stack>
                    </div>
                   
                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={6} className="more-people">
                        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen6} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Natasha K..." src={natashaheadshot} />
                            <p className="rishab-jain-text" >Natasha K.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen7} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Ram Goel" src="https://www.oregonlive.com/resizer/fD3-r7rJ1Y0uZJqwFhLvyEp_K24=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/3XBUEQR3XFB2DKYBGJVYTQRNAQ.jpg" />
                            <p className="rishab-jain-text">Ram G.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen8} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Gopal T." src="https://i.imgur.com/DwAaHel.jpeg" />
                            <p className="rishab-jain-text" >Gopal T.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                      </Stack>
                    </div>
                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={6} className="more-people">
                        
                        <div>
                          <button onClick={handleOpen11} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Rishab Jain" src={dhruv} />
                            <p className="rishab-jain-text">Dhruv R.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen4} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Shriya Bhat" src="https://imgur.com/hOveisg.jpeg" />
                            <p className="rishab-jain-text">Shriya B.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                        
          
                      </Stack>
                    </div>
                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={6} className="more-people">

                        <div>
                          <button onClick={handleOpen12} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Rishab Jain" src={yash} />
                            <p className="rishab-jain-text">Yashvir S.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>


                        <div className="jacobfpaddss" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen3} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Jacob Frie..." src="https://imgur.com/h80FmW2.jpeg" />
                            <p className="rishab-jain-text">Jacob F.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>
                      </Stack>
                    </div>   
                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={6} className="more-people">
                      
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen1} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Sahil Sood" src={sahilheadshot}/>
                            <p className="rishab-jain-text">Sahil S.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen13} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Sahil Sood" src={NataliaLopez}/>
                            <p className="rishab-jain-text">Natalia L.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>
                      
                      </Stack>
                    </div>   

                    <div data-aos="fade-up">
                      <Stack direction="row" spacing={6} className="more-people">

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <button onClick={handleOpen14} style={{backgroundColor: 'transparent', border: 'none'}}>
                            <Avatar className="avater-size-css" alt="Sahil Sood" src={Eashan}/>
                            <p className="rishab-jain-text">Eashan I.</p>
                            <p className="rishab-jain-text-tag">Learn More</p>
                          </button>
                        </div>
                      
                      </Stack>
                    </div>  

                  </div>
                </div>
              </div> 
    </div>
         
        
    </div>
  )
}

export default BoardofAdvisors
