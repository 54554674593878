import React, { useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextField from '@mui/material/TextField';
import AOS from "aos";
import { Link } from 'react-router-dom';
import '../App.css'
import logo from '../irofullpng.webp'; 
import Announcement from '../components/annoucement';


function SemiCurriculum() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  return (
    <div>
    
        <div className="container">
          <div className='row rowpad1'>
              {/* <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                <p className="maintitle" style={{textAlign: 'center', marginBottom: '50px'}}>The Curriculum</p>
              </div> */}
            </div>
            <div className='row psuhonbttomsmallscreen'>
              <div className='col' data-aos="fade-up">
              
              <div className="container" >
        
                {/* <img src={logo} className="id-logo-css-iro-dhfgasdfjhgasdjfhagsdkfhasdfgsjdhf" alt=""/> */}

                <h1 className="bigblueonthewing">IRO Official Curriculum Guide For Semifinal Exam:</h1>
                <br className="br"/>
              
              </div>
            </div>
            
              </div>
              <div className="row rowpad1" style={{alignItems: "center", justifyContent: "center"}}>

                  <iframe className="hwotaniroclub" src="https://docs.google.com/document/d/1RtW6WQBBiKmMRSBJxRCpXn_nWVTw_nxMW6rKSxB_OgA/preview#view=FitH"  allow="autoplay"></iframe>
                  
                  

                  </div>


      </div>
    </div>
  )
}

export default SemiCurriculum
