// PracticeExams
import { useEffect } from 'react';
import Announcement from '../components/annoucement';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PracticeExams() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    
        <div className="container">
        <div className='col' style={{paddingTop: "50px"}}>
          <p className="maintitle bigblueonthewing">Mock Open Exams</p>
        </div>
        <Box sx={{ width: '100%' }}>
        <Stack direction="row" style={{ overflowX: 'auto' }} spacing={1}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
        <Tab label="Question 1" {...a11yProps(0)} />
        <Tab label="Question 2" {...a11yProps(1)} />
        <Tab label="Question 3" {...a11yProps(2)} />
        <Tab label="Question 4" {...a11yProps(3)} />
        <Tab label="Question 5" {...a11yProps(4)} />
        <Tab label="Question 6" {...a11yProps(5)} />
        <Tab label="Question 7" {...a11yProps(6)} />
        <Tab label="Question 8" {...a11yProps(7)} />
        <Tab label="Question 9" {...a11yProps(8)} />
        <Tab label="Question 10" {...a11yProps(9)} />
        <Tab label="Question 11" {...a11yProps(10)} />
        <Tab label="Question 12" {...a11yProps(11)} />
        <Tab label="Question 13" {...a11yProps(12)} />
        <Tab label="Question 14" {...a11yProps(13)} />
        <Tab label="Question 15" {...a11yProps(14)} />
        <Tab label="Question 16" {...a11yProps(15)} />
      </Tabs>
    </Box>
  </Stack>


          <CustomTabPanel value={value} index={0}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/158QJX8t9FM0IqQkOJqPJPOiPmdCRQQE2/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1AkhSgcDSCoSq8RAVuYXPmuK72nwp9722/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1gzHPI0UsMdUDMN6ERRSRbmbYacksxuxS/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1HKd9WKecnON3jWOljFGt4PsqYp6v494x/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1mWlnvsOxLoTH05w6EI-c4dUHBedISAE8/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1VOcuQQn_wPrbMLYPO_r57l9o5XEujT0v/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1z9vVHCDOB54Z-ekityXGBj85BXgWC58h/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1DaS4_ZYsQL8C3y8dVi1j_DphflSYoKQ6/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1cHeuQIj3CmYQwU7tDg7MbVy-FqqYNZCx/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1fKO-wm4McnwdYCBLJNc5gH9_PfDO8cpP/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1bzMySh29AqcV0kK8n1qScxryhB8F7oap/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1QSM8XfsLs5Vlphnpm7DhXKqdeiBt3Rtc/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1fhfcD-2FPw3KEBwqmd1-t0Y2BXY_yOno/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1KFJhObuCxZLD_WTEaXDHQQm9H-YV2N0k/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/10HnmmFtcRfpV25DkM19dmUuqHMLcTnXJ/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/139F75DZXD8rV3Ax5mj3ubtIca3wge4pi/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={8}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1yrRJSh_RXLRXOT2HfGfzdO6_7KoeNUUN/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1LdrLCbetQi4tFuKI4I2czyrp-uh3PDYR/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={9}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1O5RTcscuG7XEwEh3VnVJPZfR2yFz31oH/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1E_nUSSXAHmgaOo90ql760c8lwERTpmxR/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={10}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1PUnIKqZ-ntGyNvbjNavhX6Xbx9yGdzZD/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1_5N8HeZBDKjgMlR5ZuSuLjM24rz4HFs2/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={11}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1nzV2n86G9-MzYOQKdxIR8eKVmWJnQYq_/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1X0BAU_G3-uogEWrWjpWvj14hsomwbdn9/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1J6X7K8KmPj45LsfL74PiQ0F6ZP92D6Ih/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1VD3z-ughgEFh9BwTxCSqxiI1BpDS9pPH/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={13}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1PQgtcDTpSuTyJ_IITVmvf4BIF_30-0mF/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1YSbTOfyeQ3r_Ds5uyEBX5iwqqtZX18Fv/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={14}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1Xj9CRB_9x5qR4qxQx27z_C4pFRQik2FT/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1IYqKbh645xIvGz3d9IaBk5rI4KoYkQ60/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={15}>
            <Typography variant="caption" display="block" gutterBottom>
              Note: Answer Key Below
            </Typography>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1rSzZcH-SYlprmTERVKSfdNss9TyzgT9j/preview#view=FitH" allow="autoplay"></iframe>
            <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1uInpAu9a3ogZMfaHVTLBPRKUfD5fkYen/preview#view=FitH" allow="autoplay"></iframe>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}
