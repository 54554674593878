import "./App.css";
import Announcement from "./components/annoucement";
import Feewaiver from "./pages/Feewaiver";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from "./Home";
import About from './pages/About'
import Resources from "./pages/Resources";
import { useStateValue } from "./StateProvider";
import Login from './Login';
import Time from './SignInPages/Time'
import Navbar from "./Navbar";
import Footer from "./Footer";
import NavbarTwo from "./SignInPages/NavbarTwo";
import PageNotFound from "./PageNotFound";
import Register from "./Register";
import Directory from "./pages/Directory"
import TermOfService from "./TermOfService";
import MediaContact from "./pages/MediaContact";
import MediaKit from "./pages/MediaKit"; 
import StartIROClub from "./pages/StartIROClub";
import RulesandRegulations from "./pages/RulesandRegulations";
import Reviewandvideos from "./pages/Reviewandvideos";
import RegistrationandEligibility from "./pages/RegistrationandEligibility";
import PressReleases from "./pages/PressReleases"; 
import PracticeExamsTwo from "./pages/PracticeExamsTwo";
import PracticeExams from "./pages/PracticeExams";
import AboutIRO from "./pages/AboutIRO";
import News from "./pages/News"
import Winners from "./pages/Winners";
import WinnersTwo from "./pages/WinnersTwo";
import WinnersThree from "./pages/WinnersThree";
import SemiCurriculum from "./pages/SemiCurriculum";
import ClubRegistration from "./pages/ClubRegistration";
import ClubByLaws from "./pages/ClubByLaws";
import ClubPresentationSlides from "./pages/ClubPresentationSlides";
import Blog from "./pages/Blog";
import Textbook from "./pages/Textbook";
import OpensResultsAndHonorRollReleased from "./components/Opens-results-and-honor-roll-released";
import DistinguishedScholar from "./pages/DistinguishedScholar";
import Semifinalists from "./pages/Semifinals";


function App() {

  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      dispatch({ type: 'SET_USER', user: storedUser });
    }
  }, []);

  // Save user login status to local storage whenever user state changes
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);


  return (
    <div className="App">
      <div className="app">

        {!user ? (
            <>
              
              <Navbar />
              <Announcement />
              <Routes>
              
                <Route path="/" element={<Home />} />
                <Route path="/textbook" element={<Textbook/>}/>
                <Route path='/blog' element={<Blog/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/resources' element={<Resources/>} />
                <Route path='/login' element={<Login />} />
                {/* <Route path='/register' element={<Register />} /> */}
                <Route path='/terms-of-service' element={<TermOfService />} />
                <Route path='/media-contact' element={<MediaContact />} />
                <Route path='/fee-waiver-form' element={<Feewaiver/>} />
                <Route path='/media-kit' element={<MediaKit />} />
                <Route path='/finalists' element={<WinnersTwo />} />
                <Route path='/winners' element={<WinnersThree />} />
                <Route path='/semis-results' element={<Winners />} />
                <Route path='/iro-in-the-news' element={<News />} />
                <Route path='/start-iro-club' element={<StartIROClub />} />
                <Route path='/clubs' element={<StartIROClub />} />
                <Route path='/rules-and-regulations' element={<RulesandRegulations />} />
                <Route path='/semi-curriculum' element={<SemiCurriculum />} />
                <Route path='/curriculum' element={<Reviewandvideos />} />
                <Route path='/registration-and-eligibility' element={<Register />} />
                <Route path='/press-releases' element={<PressReleases />} />
                <Route path='/semifinal-practice-exams' element={<PracticeExamsTwo />} />
                <Route path='/mocks' element={<PracticeExams />} />
                <Route path='/practice-exams' element={<PracticeExams />} />
                <Route path='/about-iro' element={<AboutIRO />} />
                <Route path='/forms/club-registration' element={<ClubRegistration />} />
                <Route path='/files/club-bylaws' element={<ClubByLaws />} />
                <Route path='/files/club-presentation-slides' element={<ClubPresentationSlides />} />
                <Route path='/directory' element={<Directory />} />
                <Route path='/opens-results-and-honor-roll-released' element={<OpensResultsAndHonorRollReleased />} />
                <Route path='/distinguished-scholar' element={<DistinguishedScholar />} />
                <Route path='/semifinalists' element={<Semifinalists />} />
                <Route path='*' element={<PageNotFound />} />

              </Routes>

              <Footer />

            </>
          ) : (
            <>
              <NavbarTwo />
              <Routes>
                <Route path='/Time' element={<Time/>} />
              </Routes>
            </>
          )}

      </div>
    </div>
  );
}

export default App;
