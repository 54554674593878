import React from 'react';
import Typography from '@mui/material/Typography';

function Winners() {
  return (
    <div className="container">
      <div className="col" style={{ paddingTop: "50px" }}>
        <Typography style={{ fontSize: "1.5ch" }} variant="overline" display="block" gutterBottom>
          3/20/25
        </Typography>
        <div className="col" style={{ paddingTop: "50px" }}>
          <p className="maintitle bigblueonthewing">Distinguished Scholar - Top 20%</p>
        </div>
      </div>
      <div className="row rowpad1" data-aos="fade-up">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Survani Sinha</li>
          <li className="list-group-item">Roshini Selvakumar</li>
          <li className="list-group-item">Abdullah Mohammed</li>
          <li className="list-group-item">Ram Sumedh Besta</li>
          <li className="list-group-item">Peerzada Shahmeer Alum</li>
          <li className="list-group-item">Alastair Go</li>
          <li className="list-group-item">C. Tibet Pozam</li>
          <li className="list-group-item">Sofia Kazintseva</li>
          <li className="list-group-item">Oechsel Theo</li>
          <li className="list-group-item">Srishanth Ravi</li>
          <li className="list-group-item">Muhammadyahyo</li>
          <li className="list-group-item">Jordyn Szczesniak</li>
          <li className="list-group-item">Klair Tan</li>
          <li className="list-group-item">Elif Bilge Ka≈ü</li>
          <li className="list-group-item">Aidan Wong</li>
          <li className="list-group-item">Anshi Elizabeth Thomas</li>
          <li className="list-group-item">Samaira Khan</li>
          <li className="list-group-item">Siyeong Park</li>
          <li className="list-group-item">Jinghan Zhang</li>
          <li className="list-group-item">Alyson Henderson</li>
          <li className="list-group-item">Claire Lily Huang</li>
          <li className="list-group-item">Vyom Uppar</li>
          <li className="list-group-item">Miguel Christopher Mok</li>
          <li className="list-group-item">Deren Toker</li>
          <li className="list-group-item">Tianyu Li</li>
          <li className="list-group-item">Harshil Jegan</li>
          <li className="list-group-item">Utsav Saroha</li>
          <li className="list-group-item">Rithvik sai reddy kambham</li>
          <li className="list-group-item">Jiexi Pan</li>
          <li className="list-group-item">Marie-Jose Rohayem</li>
          <li className="list-group-item">Yeeun Choi</li>
          <li className="list-group-item">Vihaan Gupta</li>
          <li className="list-group-item">Tapasya illuri</li>
          <li className="list-group-item">Hedy Tang</li>
          <li className="list-group-item">AldricBenala</li>
          <li className="list-group-item">Atul Ganesh</li>
          <li className="list-group-item">Jacquelyn Inocencio</li>
          <li className="list-group-item">Siri Pulakandam</li>
          <li className="list-group-item">Anoushka Swaminathan</li>
          <li className="list-group-item">Srichandra Pallerla</li>
          <li className="list-group-item">Roaran Katelan</li>
          <li className="list-group-item">Anusha Sudheer</li>
          <li className="list-group-item">Chunghyun Kawata</li>
          <li className="list-group-item">Suhaan Khan</li>
          <li className="list-group-item">Jobanjot Singh Madhok</li>
          <li className="list-group-item">Laksan Mohan</li>
          <li className="list-group-item">Reyo Apollo Schwilk Khan</li>
          <li className="list-group-item">Morgan Gruber</li>
          <li className="list-group-item">Eesha Vanamala</li>
          <li className="list-group-item">Nimeesha Komatireddy</li>
          <li className="list-group-item">Michelle Diep</li>
          <li className="list-group-item">Feride Ada Senturk</li>
          <li className="list-group-item">Iraj Gupta</li>
          <li className="list-group-item">Yara Aziz</li>
          <li className="list-group-item">Rohini Vedam</li>
          <li className="list-group-item">Morgan Duran</li>
          <li className="list-group-item">Sydney Yu</li>
          <li className="list-group-item">Sumedha Saha</li>
          <li className="list-group-item">Paridhi Goel</li>
          <li className="list-group-item">Avika Taneja</li>
          <li className="list-group-item">Deeksha shashidhar</li>
          <li className="list-group-item">Arjan Gavin Singh Waraich</li>
          <li className="list-group-item">Briana Codrescu</li>
          <li className="list-group-item">Yena Kim</li>
          <li className="list-group-item">Lasya Pannala</li>
          <li className="list-group-item">Aadhavan Veerendra</li>
          <li className="list-group-item">Andrew Rahana</li>
          <li className="list-group-item">Aarthi Palaniappan</li>
          <li className="list-group-item">Rehan Raj</li>
          <li className="list-group-item">Brooke Foley</li>
          <li className="list-group-item">Arya Verma</li>
          <li className="list-group-item">Veer Chopra</li>
          <li className="list-group-item">William Flaxenburg</li>
          <li className="list-group-item">Ashley Mahajan</li>
          <li className="list-group-item">Norah Majed Altamimi</li>
          <li className="list-group-item">Darshika Domma</li>
          <li className="list-group-item">Tanya Yong</li>
          <li className="list-group-item">Aryana Hou</li>
          <li className="list-group-item">Sathwika Prasad</li>
          <li className="list-group-item">Aishwarya Kamineni</li>
          <li className="list-group-item">Collin Lu</li>
          <li className="list-group-item">Tsz Chai Alan Liu</li>
          <li className="list-group-item">Haidar Jamal Haidar Haidar</li>
          <li className="list-group-item">Aaron Sethi</li>
          <li className="list-group-item">Leo Chen</li>
          <li className="list-group-item">Hayden Lee</li>
          <li className="list-group-item">Arjun Vir Jain</li>
          <li className="list-group-item">Kaushik Tatiraju</li>
          <li className="list-group-item">Zoya Chowdhury</li>
          <li className="list-group-item">Xinyi Kathy Xi</li>
          <li className="list-group-item">Anika Gursahani</li>
          <li className="list-group-item">Advithi Baddam</li>
          <li className="list-group-item">Sofia Pisareva</li>
          <li className="list-group-item">Ansel Belani</li>
          <li className="list-group-item">Avery Huang</li>
          <li className="list-group-item">Savya Bhat</li>
          <li className="list-group-item">Mischa Neo Jing Yi</li>
          <li className="list-group-item">Dhruva Vengala</li>
          <li className="list-group-item">Rohith Kolar</li>
          <li className="list-group-item">Nikhil Palempalle</li>
          <li className="list-group-item">Mridul Jain</li>
          <li className="list-group-item">Nicholas McDow</li>
          <li className="list-group-item">Eslam Essam Ahmed</li>
          <li className="list-group-item">Chaitra Jogiraju</li>
          <li className="list-group-item">Eric Jia Zhang</li>
          <li className="list-group-item">Grace Yuan</li>
          <li className="list-group-item">Gagana Latchupatula</li>
          <li className="list-group-item">Sean Haejun Lee</li>
          <li className="list-group-item">Annalyse McGhee</li>
          <li className="list-group-item">Narendran Karikkathil</li>
          <li className="list-group-item">Parth Agarwal</li>
          <li className="list-group-item">Lina AlKhayyal</li>
          <li className="list-group-item">Oviya Mathi</li>
          <li className="list-group-item">Pranav Mohankumar</li>
          <li className="list-group-item">Petechapat Areewong</li>
          <li className="list-group-item">Maya Trutschl</li>
          <li className="list-group-item">Pranav Kunadharaju</li>
          <li className="list-group-item">Aadi Shah</li>
          <li className="list-group-item">Neel Jain</li>
          <li className="list-group-item">Martin Joe Prem Kumar</li>
          <li className="list-group-item">Jennie Zhang</li>
          <li className="list-group-item">Jay Patel</li>
          <li className="list-group-item">Piyush Acharya</li>
          <li className="list-group-item">Sanjana Rajaram</li>
          <li className="list-group-item">Wenshu Wang</li>
          <li className="list-group-item">Stepan Galstyan</li>
          <li className="list-group-item">Suraj Swaminathan</li>
          <li className="list-group-item">Tavishi Sinha</li>
          <li className="list-group-item">Reyansh Mohan Lal</li>
          <li className="list-group-item">Kausthubha Naraparaju</li>
          <li className="list-group-item">Jophy Lin</li>
          <li className="list-group-item">Theodore Mui</li>
          <li className="list-group-item">Ainsley Bosman</li>
          <li className="list-group-item">Matthew Young Chi</li>
          <li className="list-group-item">Ishaan Dabhade</li>
          <li className="list-group-item">Akchara Mukunthu Deepak Babu</li>
          <li className="list-group-item">Snehansh Reddy Bachala</li>
          <li className="list-group-item">Srisaran Sivakumar</li>
        </ul>
      </div>
    </div>
  );
}

export default Winners;
