import React from 'react'
import Book from "../components/book"
import LazyLoad from 'react-lazyload';
import teamchallnages from "../finalist_photos/finalsteamchallange-min.webp"
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useInView } from "react-intersection-observer";

import { useState, useEffect, forwardRef, Fragment } from 'react';

function AboutIRO() {

  const [pageLoad, setPageLoad] = useState(false);
  const { ref, inView, entry } = useInView({threshold: 0});


  useEffect(() => {
    if (pageLoad === false && inView) {
      setPageLoad(true);
      var keyboardEvent = document.createEvent('KeyboardEvent');
var initMethod = typeof keyboardEvent.initKeyboardEvent !== 'undefined' ? 'initKeyboardEvent' : 'initKeyEvent';
    
keyboardEvent[initMethod](
  'keydown', // event type: keydown, keyup, keypress
  true, // bubbles
  true, // cancelable
  window, // view: should be window
  false, // ctrlKey
  false, // altKey
  false, // shiftKey
  false, // metaKey
  40, // keyCode: unsigned long - the virtual key code, else 0
  0, // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
);
document.dispatchEvent(keyboardEvent);

      let iframe = document.createElement('iframe');
      iframe.setAttribute('src', `https://www.youtube.com/embed/LmKxVI8i3iU?si=REndfM9Y0Jy1sEI&autoplay=1&rel=0&controls=0&showinfo=0`);
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('width', '853');
      iframe.setAttribute('height', '480');

    document.querySelector('.video-responsive').appendChild(iframe);
    }
  }, [inView])

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  const navigate = useNavigate() 

  const loginpage = () => {
    navigate("/practice-exams")
  
  }

  return (
   
    <div class="container">

      <div className='row rowpad1'>
                <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                  <p  className="maintitle bigblueonthewing">How to Prepare for IRO</p>
                </div>
              </div>

              <div className='row rowpad1'>
                <div className='col' data-aos="fade-up">
                {/* <Skeleton variant="rectangular" width={500} height={300} /> */}

                <iframe width="551" height="310" src="https://www.youtube.com/embed/N3jywbAqfNU?si=RghsjMZCMK-o7-AT" className="rishab-jain-video-css" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                {/* <iframe width="560" height="310" src="https://www.youtube.com/watch?v=tFdOjXrVLp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="rishab-jain-video-css"></iframe> */}
                </div>

                <div className='col' data-aos="fade-up">
                <p className="lowertitle-ls">The International Research Olympiad is a competition that doesn't just rely on memorization of content or a specific type of problem. <br/><br/> It aims to test crucial analytical skills that are essential to succeed as a <b>researcher</b>.</p>
                </div>
              </div>
      <div className='row rowpad1' data-aos="fade-up">

        <div  style={{paddingTop: "50px"}}>
            <p className="maintitle bigblueonthewing">About the Exam</p>
        </div>

  

        <div className='col' style={{paddingTop: "50px"}}>
            <p className="secondarytitle">Exam Information</p>
        </div>

        <div className="iro-teamchallnage-finalsts-1">
            <p className="finalspressrelases lowertitle">
            The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2025 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.
            </p>
         </div>

        

         <div className="iro-teamchallnage-finalsts">
            <div className='row align-items-center'>
                  <div className="col-8 ">
                    <p className="finalspressrelases lowertitle">
                    The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2025 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.
                    </p>
                  </div>
                  <div className="col-4">
                    <div className="cool-image-guy-holding-blocks-od">
                  
                      <LazyLoad height={200} offset={100} once>
                        <img style={{width: "400px"}} src={teamchallnages} className="cool-image-guy-holding-blocks"/>
                    </LazyLoad>

                    </div>

                  </div>
                  <br/><br/>
            </div>
         </div>

         <Book/>
        

          
         

          <div className='col' style={{paddingTop: "50px"}}>
            <p className="secondarytitle">Opens</p>
        </div>
        <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
        DATE: MARCH 1, 2025 | 8am ET and 8pm ET
          </Typography>
          <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
          LOCATION: ONLINE 
          </Typography>
          <a style={{color: "black"}} href="https://www.internationalresearcholympiad.org/curriculum">
            <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
            CURRICULUM
            </Typography>
          </a>

          <p className="lowertitle">
              The "Opens" round is a comprehensive assessment, encompassing diverse elements such as analyzing research papers, interpreting scientific data, and tackling question formation and inquiry. This stage aims to test participants’ analytical skills and critical thinking. The Opens round will consist mostly of multiple-choice questions and answers. <br/>

              Registered students will receive information about their login information for accessing the IRO Open Exam. The Open exam round is hosted virtually, via a portal on our website. Students around the world will be able to participate in this round.

          </p>


          <div className='col' style={{paddingTop: "50px"}}>
            <p className="secondarytitle">Semifinals</p>
        </div>
        <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
        DATE: APRIL 5, 2025 | 8am ET and 8pm ET
          </Typography>
          <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
          LOCATION: VIRTUAL 

          </Typography>
          <a style={{color: "black"}} href="https://www.internationalresearcholympiad.org/semi-curriculum">
            <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
            CURRICULUM
            </Typography>
          </a>

          <p className="lowertitle">
          The top 10% of participants will advance to the semifinals from the Open examinations. The semifinals elevate the challenge of introducing free-response questions judged by qualified researchers delving deeper into participants' ability to articulate and apply their understanding of scientific concepts.

          </p>

          <div className='col' style={{paddingTop: "50px"}}>
            <p className="secondarytitle">Finals</p>
        </div>
        <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
        DATE: JUNE 6-8, 2025 | DURATION: 2 days
          </Typography>
          <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
          LOCATION: IN-PERSON AT HARVARD SQUARE

          </Typography>


          <p className="lowertitle">
          The pinnacle of the competition is finals, where the top 15 participants will engage in intense competition for the coveted gold, silver, and bronze medals in the IRO. We will be hosting in-person finals at Harvard Square on May 31st to June 2nd with accommodation expenses covered for finalists. Additional sponsor prizes, media opportunities, and recognition will be awarded. The exam content has been designed, reviewed, and validated by our team, student board of advisors, and board of advisors.

          </p>
          <div className='row rowpad1 align-items-center' data-aos="fade-up" >
            <iframe className="image-css-invert-none" style={{width: '100%', height: '500px'}} src="https://www.youtube.com/embed/LmKxVI8i3iU?si=P5BMrJcnarl3DaTX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

          <div className='col' style={{paddingTop: "50px"}}>
     
            <button className="secondarytitle secondinsideytwo underline-animation" type="submit" onClick={loginpage}>IRO Study Resources</button><ArrowForwardIcon style={{marginBottom: "7px"}}/>
        </div>

        
      </div>
    </div>
  )
}

export default AboutIRO;
